<template>
	<div class="home-container">
		<div class="pageheader">
			<van-nav-bar class="nav-bar">
				<template #left>
					<van-icon name="arrow-left" color="#000000" @click="back()" />
					<span>{{ $t('index.settit2') }}</span>
				</template>
			</van-nav-bar>
		</div>
		<div class="homepage">
			<div class="link" @click="active_store=true">
				<div class="tit ty">{{ $t('setting.storeset1') }}</div>
				<van-icon name="arrow" color="#fff" />
			</div>
			<div class="link">
				<div class="tit hf">{{ $t('setting.storeset2') }}</div>
				<van-icon name="arrow" color="#fff" />
			</div>
			<div class="link" @click="active_msn=true">
				<div class="tit sj">{{ $t('setting.storeset3') }}</div>
				<van-icon name="arrow" color="#fff" />
			</div>
		</div>	
		<van-popup v-model="active_store">
			<div class="popupbox">
				<div class="popuptit">{{ $t('setting.storeset1') }}</div>
				<div class="sel_lang">
					<div style="margin-bottom: 17px;">
						<div class="tit">{{ $t('setting.store_name') }}</div>
						<van-field v-model="storety.name" class="input"></van-field>
					</div>
					<div style="margin-bottom: 17px;">
						<div class="tit">{{ $t('setting.store_phone') }}</div>
						<van-field v-model="storety.phone" class="input"></van-field>
					</div>
					<div style="margin-bottom: 17px;">
						<div class="tit">{{ $t('setting.store_address') }}</div>
						<van-field v-model="storety.address" class="input"></van-field>
					</div>
					<div style="margin-bottom: 17px;">
						<div class="tit">{{ $t('setting.store_title') }}</div>
						<van-field v-model="storety.title" class="input"></van-field>
					</div>
					<div style="margin-bottom: 17px;">
						<div class="tit">{{ $t('setting.store_des') }}</div>
						<van-field v-model="storety.des" class="input"></van-field>
					</div>
				</div>
				<div class="btnlist">
					<div @click="active_store=false">{{ $t('common.qxbtn') }}</div>
					<div @click="do_store()">{{ $t('common.okbtn') }}</div>
				</div>
			</div>
		</van-popup>
		
		<van-popup v-model="active_msn">
			<div class="popupbox">
				<div class="popuptit">{{ $t('setting.storeset1') }}</div>
				<div class="sel_lang">
					<div style="margin-bottom: 17px;">
						<div class="tit">
							<span>{{ $t('setting.link_fb') }}</span>
							<span>{{ $t('setting.linktip') }}</span>
						</div>
						<van-field v-model="storety.facebook" class="input"></van-field>
					</div>
					<div style="margin-bottom: 17px;">
						<div class="tit">
							<span>{{ $t('setting.link_in') }}</span>
							<span>{{ $t('setting.linktip') }}</span>
						</div>
						<van-field v-model="storety.instagram" class="input"></van-field>
					</div>
					<div style="margin-bottom: 17px;">
						<div class="tit">
							<span>{{ $t('setting.link_tw') }}</span>
							<span>{{ $t('setting.linktip') }}</span>
						</div>
						<van-field v-model="storety.google" class="input"></van-field>
					</div>
					<div style="margin-bottom: 17px;">
						<div class="tit">
							<span>{{ $t('setting.link_gg') }}</span>
							<span>{{ $t('setting.linktip') }}</span>
						</div>
						<van-field v-model="storety.twitter" class="input"></van-field>
					</div>
					<div style="margin-bottom: 17px;">
						<div class="tit">
							<span>{{ $t('setting.link_ytb') }}</span>
							<span>{{ $t('setting.linktip') }}</span>
						</div>
						<van-field v-model="storety.youtube" class="input"></van-field>
					</div>
				</div>
				<div class="btnlist">
					<div @click="active_msn=false">{{ $t('common.qxbtn') }}</div>
					<div @click="do_store()">{{ $t('common.okbtn') }}</div>
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
export default {
	data() {
		return {
			active_store:false,
			active_msn:false,
			seller:[],
			uid:0,
			storety:{
				name:'',
				phone:'',
				address:'',
				title:'',
				des:'',
				facebook:'',
				instagram:'',
				google:'',
				twitter:'',
				youtube:'',
			}
			
		};
	},
	mounted() {
		
	},
	created() {
		if (localStorage.getItem('access_token')) {
			this.uid = localStorage.getItem("sellerid");
			this.getUserInfo();
			this.getStoretongyong();
		} else {
			this.$router.push({path:'/Login'})
		}
	},
	methods: {
		back(){
			this.$router.push({path:'/Home'})
		},
		getUserInfo() {
			this.$http({
				method: 'get',
				url: 'shopinfo',
				data:{
					id:this.uid
				}
			}).then(res => {
				if(res.code==1){
					this.seller = res.data;
				}
			})
		},
		getStoretongyong(){
			this.$http({
				method: 'get',
				url: 'getstorety',
				data:{
					id:this.uid
				}
			}).then(res => {
				if(res.code==1){
					this.storety = res.data;
				}
			})
		},
		do_store(){
			if(this.storety.name==''){
				this.$toast(this.$t("setting.store_input")+this.$t("setting.store_name"));
				return false;
			}
			this.$http({
				method: 'post',
				url: 'do_storety',
				data:this.storety
			}).then(res => {
				if(res.code==1){
					this.$toast(this.$t("setting.uploadSuccess"));
					this.active_store=false;
					this.active_msn=false;
				}
			})
		}
	}
}

</script>

<style lang='less' scoped>
.pageheader{
	position: fixed;
	width: 100%;
	z-index: 9999;
}
::v-deep .van-nav-bar {
	height: 48px;
	background: #fff;
	box-shadow: 0 1px 6px #ccc;
}

::v-deep .van-nav-bar__content {
	padding: 0 10px;
}

::v-deep .van-nav-bar__left {
	padding-left:10px;
	color: #000;
	font-size: 17px;
	font-weight: bold;
}
.homepage{
	padding: 73px 17px 60px;
	background: #f9f9f9;
}
.link{
	display: flex;
    align-items: center;
    justify-content: space-between;
    height: 88px;
    margin-bottom: 17px;
    background: #000;
    box-shadow: 0 0 11px 0px rgba(0, 0, 0, .15);
    border-radius: 6px;
	padding: 0 20px;
	.tit{
		font-size: 17px;
		font-weight: 600;
		color: #fff;
		padding-left: 30px;
		height: 30px;
		line-height: 30px;
	}
	.ty{
		background: url(../images/home/ico_s3.png) no-repeat left center;
		background-size: 20px;
	}
	.hf{
		background: url(../images/home/ico_s4.png) no-repeat left center;
		background-size: 20px;
	}
	.sj{
		background: url(../images/home/ico_s5.png) no-repeat left center;
		background-size: 20px;
	}
}

::v-deep .van-popup {
	width:80%;
}
.popupbox{
	padding: 22px;
	.popuptit{
		padding: 0 22px;
		margin-bottom: 22px;
		font-size: 18px;
		font-weight: 600;
		color: #000;
		line-height: 22px;
		text-align: center;
	}
	.btnlist{
		display: flex;
		justify-content: space-between;
		padding-top: 22px;
		div{
			display: flex;
			align-items: center;
			justify-content: center;
			width: 45%;
			height: 50px;
			border-radius: 6px;
			border: 1px solid #000;
			font-size: 15px;
			font-weight: 600;
			color: #000;
		}
		div:last-child{
			color: #fff;
			background: #000;
		}
	}
}
.sel_lang{
	.tit{
		display: flex;
		justify-content: space-between;
		span{
			font-size: 14px;
		}
		span:first-child{
			font-weight: 700;
		}
		span:last-child{
			color:#989898;
			font-size: 13px;
		}
	}
}

.input{
	border: solid 1px #dadbde;
	border-radius: 4px;
	height: 38px;
	padding: 6px 9px;
}
</style>
