<template>
	<div class="home-container">
		<div class="pageheader">
			<van-nav-bar class="nav-bar">
				<template #left>
					<span>{{ $t('order.pagetitle') }}</span>
				</template>
			</van-nav-bar>
		</div>
		<div class="homepage">
			<div class="protabs">
				<div :class="otype==0?'sel':''" @click="tabselect(0)">{{ $t('order.tab_all') }}</div>
				<div :class="otype==1?'sel':''" @click="tabselect(1)">{{ $t('order.tab_dfh') }}</div>
				<div :class="otype==2?'sel':''" @click="tabselect(2)">{{ $t('order.tab_bhz') }}</div>
				<div :class="otype==3?'sel':''" @click="tabselect(3)">{{ $t('order.tab_yvh') }}</div>
			</div>
			<div class="searchbox">
				<van-field v-model="keyword" class="input">
					<template slot="right-icon" >
						<van-icon slot="left-icon" size="20" color="#ccc" name="search" />
						<span style="color: #ccc;font-size: 16px;" @click="geshopProduct()">{{ $t('common.searchstr') }}</span>
					</template>
				</van-field>
			</div>
			<div class="selstatus">
				<div class="xiaok" @click="payactive=true">
					<div class="text">{{ paytypename }}</div>
					<van-icon name="arrow-down" color="#606266" />
				</div>
				<div class="xiaok" @click="wlactive=true">
					<div class="text">{{ wultypename }}</div>
					<van-icon name="arrow-down" color="#606266" />
				</div>
			</div>
			<div class="prolist">
				<div class="item" v-for="(item,ind) in shop_pro" :key="ind">
					<div class="left">
						<div class="tit">{{ item.code }}</div>
						<div class="time">{{ item.created_at }}</div>
						<div class="pays">{{ $t('order.str_paysta') }} - <span>{{ $t('order.paysta_'+item.payment_status) }}</span></div>
						<div class="fahs">{{ $t('order.str_fhsta') }} - <span>{{ $t('order.ddsta_'+item.delivery_status) }}</span></div>
						<div class="wuls" v-if="item.express_info==''">{{ $t('order.str_wlsta') }} - <span>{{ $t('order.wlsta_unyfah') }}</span></div>
						<div class="wuls" v-else>{{ $t('order.str_wlsta') }} - <span>{{ $t('order.wlsta_yfah') }}</span></div>
					</div>
					<div class="right">
						<div class="price">
							<span>${{ item.grand_total }}</span>
							<div>({{ $t('order.str_lirun') }} ${{ item.lirun }})</div>
						</div>
						
						<div class="btn" v-if="item.product_storehouse_status=='0'">{{ $t('order.vh_btn') }}</div>
					</div>
				</div>
			</div>
		</div>
		<van-popup v-model="payactive">
			<div class="shoppic">
				<ul>
					<li @click="payselect('99',$t('order.tab_all'))">{{ $t('order.tab_all') }}</li>
					<li @click="payselect('1',$t('order.paysta_paid'))">{{ $t('order.paysta_paid') }}</li>
					<li @click="payselect('2',$t('order.paysta_unpaid'))">{{ $t('order.paysta_unpaid') }}</li>
				</ul>
			</div>
		</van-popup>
		<van-popup v-model="wlactive">
			<div class="shoppic">
				<ul>
					<li @click="wulselect('99',$t('order.tab_all'))">{{ $t('order.tab_all') }}</li>
					<li @click="wulselect('1',$t('order.ddsta_pending'))">{{ $t('order.ddsta_pending') }}</li>
					<li @click="wulselect('2',$t('order.ddsta_confirmed'))">{{ $t('order.ddsta_confirmed') }}</li>
					<li @click="wulselect('3',$t('order.ddsta_on_the_way'))">{{ $t('order.ddsta_on_the_way') }}</li>
					<li @click="wulselect('4',$t('order.ddsta_delivered'))">{{ $t('order.ddsta_delivered') }}</li>
				</ul>
			</div>
		</van-popup>
	</div>
</template>

<script>
export default {
	data() {
		return {
			shop_pro:[],
			show_pigimg:'',
			payactive:false,
			wlactive:false,
			seller:[],
			uid:0,
			paytype:'99',  //0未支付，1已支付
			wultype:'99',  //0未支付，1已支付
			paytypename:this.$t('order.tab_all'),
			wultypename:this.$t('order.tab_all'),
			keyword:'',
			otype:0
		};
	},
	mounted() {
		
	},
	created() {
		if (localStorage.getItem('access_token')) {
			this.uid = localStorage.getItem("sellerid");
			this.getUserInfo();
			this.geshopProduct();
		} else {
			this.$router.push({path:'/Login'})
		}
	},
	methods: {
		tabselect(n){
			this.otype = n;
			this.geshopProduct();
		},
		payselect(n,str){
			this.paytype = '';
			if(n!='99'){
				this.paytype=n;
			}
			this.paytypename = str;
			this.payactive = false;
			this.geshopProduct();
		},
		wulselect(n,str){
			this.wultype = '';
			if(n!='99'){
				this.wultype=n;
			}
			this.wultypename = str;
			this.wlactive = false;
			this.geshopProduct();
		},
		getUserInfo() {
			this.$http({
				method: 'get',
				url: 'shopinfo',
				data:{
					id:this.uid
				}
			}).then(res => {
				if(res.code==1){
					this.seller = res.data;
				}
			})
		},
		geshopProduct(){
			this.$http({
				method: 'get',
				url: 'shoporder',
				data:{
					otype:this.otype,
					paytype:this.paytype,
					wultype:this.wultype,
					keyword:this.keyword
				}
			}).then(res => {
				if(res.code==1){
					this.shop_pro = res.data;
				}
			})
		}
	}
}

</script>

<style lang='less' scoped>
.pageheader{
	position: fixed;
	width: 100%;
	z-index: 1999;
}
::v-deep .van-nav-bar {
	height: 48px;
	background: #fff;
	box-shadow: 0 1px 6px #ccc;
}

::v-deep .van-nav-bar__content {
	padding: 0 10px;
}

::v-deep .van-nav-bar__left {
	padding-left:10px;
	color: #000;
	font-size: 17px;
	font-weight: bold;
}

.homepage{
	padding: 73px 17px 60px;
	background: #f9f9f9;
}
.protabs{
	display: flex;
    align-items: center;
    justify-content:space-around;
	div{
		overflow: hidden;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 46px;
		font-size: 16px;
		font-weight: bold;
		color:#a5a5a5;
	}
	div.sel{
		color:#000;
		border-bottom: solid 2px #000;
	}
}
.searchbox{
	margin-top: 26px;
	::v-deep .van-field__right-icon {
		display: flex;
		align-items: center;
	}
}
.selstatus{
	display: flex;
	margin-top: 26px;
	justify-content: space-between;
	.xiaok{
		background: #fff;
		width: 48%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 17px;
		height: 44px;
		.text{
			font-size: 14px;
		}
	}
}
.prolist{
	margin-top: 17px;
	.ptit{
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 22px;
		font-weight: 700;
		color: #000;
		line-height: 33px;
	}
	.item{
		overflow: hidden;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 17px 22px;
		background: #fff;
		border-radius: 6px;
		margin-bottom: 17px;
		.right{
			width: 100px;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			.price{
				text-align: right;
				span{
					margin-bottom: 5px;
					font-size: 22px;
					font-weight: 600;
					color: #000;
					line-height: 22px;
				}
				div{
					font-size: 13px;
					color: #000;
					line-height: 22px;
				}
			}
			.btn{
				display: flex;
				align-items: center;
				justify-content: center;
				width: 99px;
				height: 37px;
				margin-top: 46px;
				border-radius: 4px;
				font-size: 13px;
				font-weight: 600;
				background: #a8d3ff;
			}
		}
		.left{
			.tit{
				margin-bottom: 17px;
				font-size: 15px;
				font-weight: 600;
				color: #000;
				line-height: 22px;
			}
			.time{
				font-size: 13px;
				color: #000;
				line-height: 22px;
				background: url(../images/order/ico_1.png) no-repeat left 2px;
				background-size: 20px;
				padding-left: 30px;
			}
			.pays{
				font-size: 13px;
				color: grey;
				line-height: 22px;
				background: url(../images/order/ico_2.png) no-repeat left 2px;
				background-size: 20px;
				padding-left: 30px;
			}
			.fahs{
				font-size: 13px;
				color: grey;
				line-height: 22px;
				background: url(../images/order/ico_3.png) no-repeat left 2px;
				background-size: 20px;
				padding-left: 30px;
			}
			.wuls{
				font-size: 13px;
				color: grey;
				line-height: 22px;
				background: url(../images/order/ico_4.png) no-repeat left 2px;
				background-size: 20px;
				padding-left: 30px;
			}
		}
	}
}
::v-deep .van-popup{
	border-radius: 16px;
    min-width: 80%;
}
.shoppic{
	li{
		text-align: center;
		padding: 11px 17px;
		font-size: 16px;
		font-weight: 600;
		border-bottom: 1px solid #ccc;
	}
}
</style>
