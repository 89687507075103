export default {
	'webconfig': '/api/common/webconfig', //系统配置
	'login': '/api/user/login', //系统配置
	'shopinfo': '/api/shops/info', //系统配置
	'shoporder': '/api/orders/order', //系统配置
	'shopordershow': '/api/shops/ordershow', //系统配置
	'orderfahuo': '/api/shops/orderfahuo', //系统配置
	'productlist': '/api/shops/product', //系统配置
	'productcat': '/api/shops/categories', //系统配置
	'productsubcat': '/api/shops/subcategories', //系统配置
	'productadd': '/api/shops/productadd', //系统配置
	'ordercount': '/api/orders/ordercount', //系统配置
	'shop_product': '/api/shops/shopproduct', //系统配置
	'showreviews': '/api/shops/showreviews', //系统配置
	'imgupload': '/api/common/upload', //系统配置
	'spreaduser': '/api/shops/getspreaduser', //系统配置
	'getstorety': '/api/shops/getstorety', //系统配置
	'do_storety': '/api/shops/do_storety', //系统配置
	'do_usinfo': '/api/user/do_usinfo', //系统配置
	'do_loginpwd': '/api/user/do_loginpwd', //系统配置
	'do_editbankinfo': '/api/shops/do_editbankinfo', //系统配置
	'do_recharge': '/api/shops/do_recharge', //系统配置
	'getrecharge': '/api/shops/getrecharge', //系统配置
	'getwithdraw': '/api/shops/getwithdraw', //系统配置
	'do_withdraw': '/api/shops/do_withdraw', //系统配置
}
