<template>
	<div class="home-container">
		<div class="pageheader">
			<van-nav-bar class="nav-bar">
				<template #left>
					<img src="../images/logo.png" />
					<span>{{ $t('index.pagetitle') }}</span>
				</template>
				<template #right>
					<img src="../images/kefu.png" style="margin-right: 30px;" />
					<img src="../images/msg.png" />
				</template>
			</van-nav-bar>
		</div>
		<div class="homepage">
			<div class="moneybox">
				<swiper class="banner_swiper" :options="bannerSwiperOption">
					<swiper-slide>
						<div class="totalmoney">
							<div class="dian">
								<ul><li></li><li></li><li></li><li></li></ul>
							</div>
							<div class="tit">{{ $t('index.totalmoeny') }}</div>
							<div class="num">${{ seller.yongjin }}</div>
						</div>
					</swiper-slide>
					<swiper-slide>
						<div class="totalmoney">
							<div class="dian">
								<ul><li></li><li></li><li></li><li></li></ul>
							</div>
							<div class="tit">{{ $t('index.blmoeny') }}</div>
							<div class="num">${{ seller.money }}</div>
						</div>
					</swiper-slide>
				</swiper>
				<div class="swiper-pagination"></div>
			</div>
			<div class="visitor">
				<div class="visicon1">
					<div class="tit">{{ $t('index.visitit') }}</div>
					<span @click="$router.push({ path: '/views' })" class="to-icon-arrow-right"></span>
				</div>
				<div class="visicon2">
					<div>
						<span>{{ seller.shopviews.day }}</span>
						<p>{{ $t('index.todayvisi') }}</p>
					</div>
					<div>
						<span>{{ seller.shopviews.day7 }}</span>
						<p>{{ $t('index.visi7') }}</p>
					</div>
				</div>
				<div class="visicon3">
					<span>{{ seller.shopviews.day30 }}</span>
					<p>{{ $t('index.visi30') }}</p>
				</div>
			</div>
			<div class="cartbox">
				<div class="divbox">
					<div class="cart">
						<img src="../images/home/cart.png" />
						<span>{{ ordercount.total_dd }}</span>
						<div>{{ $t('order.pending') }}</div>
					</div>
				</div>
				<div class="divbox">
					<div class="tuikuan">
						<div class="imgbox">
							<img src="../images/home/tuik.png" />
							<span>{{ ordercount.total_tk }}</span>
						</div>
						<div class="str">{{ $t('index.tuikuan') }}</div>
					</div>
					<div class="say">
						<div class="imgbox">
							<img src="../images/home/say.png" />
							<span>{{ seller.zishun }}</span>
						</div>
						<div class="str">{{ $t('index.say') }}</div>
					</div>
				</div>	
			</div>
			<div class="sharebox" @click="$router.push({ path: '/share' })">
				<div style="font-size: 26px;color: #1d1e25; font-weight: bold; line-height: 33px;">{{ $t('share.tit') }}</div>
				<div style="font-size: 15px;color: #808d9e; margin-top: 2px; line-height: 24px;">{{ $t('share.in_user') }}</div>
			</div>

			<div class="orderbox">
				<div class="ordertit">{{ $t('index.ordertit') }}</div>
				<div class="ordercon">
					<div class="order">
						<div class="img"><img src="../images/home/ico_o1.png" /></div>
						<div class="num">{{ ordercount.total }}</div>
						<div class="tit">{{ $t('index.orderall') }}</div>
					</div>
					<div class="order">
						<div class="img"><img src="../images/home/ico_o2.png" /></div>
						<div class="num">{{ ordercount.total_way }}</div>
						<div class="tit">{{ $t('index.orderdd') }}</div>
					</div>
					<div class="order">
						<div class="img"><img src="../images/home/ico_o3.png" /></div>
						<div class="num">{{ ordercount.total_sh }}</div>
						<div class="tit">{{ $t('index.ordercg') }}</div>
					</div>
					<div class="order">
						<div class="img"><img src="../images/home/ico_o4.png" /></div>
						<div class="num">{{ ordercount.total_qux }}</div>
						<div class="tit">{{ $t('index.orderqux') }}</div>
					</div>
				</div>
			</div>

			<div class="storebox">
				<div class="pro">
					<div class="img"><img src="../images/home/ico_p1.png" /></div>
					<div class="des">
						<span>{{ shop_pro.length }}</span>
						<div>{{ $t('index.chanpin') }}</div>
					</div>
				</div>
				<div class="pro">
					<div class="img"><img src="../images/home/ico_p2.png" /></div>
					<div class="des">
						<span>{{ seller.rating }}</span>
						<div>{{ $t('index.pnigfen') }}</div>
					</div>
				</div>
				<div class="pro">
					<div class="img"><img src="../images/home/ico_p3.png" /></div>
					<div class="des">
						<span>{{ seller.xinyongfen }}</span>
						<div>{{ $t('index.shanejia') }}</div>
					</div>
				</div>
			</div>
			<div class="settingbox">
				<div class="settitle">{{ $t('index.settit') }}</div>
				<div class="setcon">
					<div class="item" @click="$router.push({ path: '/setname' })">
						<img src="../images/home/ico_s1.png" alt="">
						<span>{{ $t('index.settit2') }}</span>
						<div>{{ $t('index.setbtn1') }}</div>
					</div>
					<div class="item" @click="$router.push({ path: '/setting' })">
						<img src="../images/home/ico_s2.png" alt="">
						<span>{{ $t('index.settit3') }}</span>
						<div>{{ $t('index.setbtn2') }}</div>
					</div>
				</div>
			</div>
			
			<div class="productbox">
				<div class="protitle">{{ $t('index.prohottit') }}</div>
				<div class="list" v-for="(item,ind) in shop_pro" :key="ind">
					<div class="img" @click="showbigpic(item.image)"><img :src="item.image" alt=""></div>
					<div class="con">
						<div class="tit">{{ item.name }}</div>
						<div class="price">${{ item.unit_price }}</div>
					</div>
				</div>
			</div>
		</div>
		<van-popup v-model="active" get-container=".homepage">
			<div class="shoppic">
				<div class="img"><img :src="show_pigimg" /></div>
				<div style="height: 50px;font-size:16px;width:100%;text-align: center; line-height: 50px; color: #fff;background: #000;" @click="active=false">{{ $t('common.okbtn') }}</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
export default {
	data() {
		return {
			bannerSwiperOption: {
				effect: 'coverflow',
				grabCursor: true,
				centeredSlides: true,
				slidesPerView: 'auto',
				speed:800,
				autoplay:true,
				coverflowEffect: {
					rotate: 50,
					stretch: 10,
					depth: 100,
					modifier: 1,
					slideShadows : true
				},
				pagination: {
					el: ".swiper-pagination",
					clickable: true,
				}
			},
			userInfo:{
				name:'',
				phone:'',
				email:'',
				avatar:'',
				money:0.00
			},
			uid:0,
			seller:[],
			ordercount:[],
			shop_pro:[],
			show_pigimg:'',
			active:false
		};
	},
	created() {
		if (localStorage.getItem('access_token')) {
			this.uid = localStorage.getItem("sellerid");
			this.userInfo.email = localStorage.getItem("selleremail");
			this.userInfo.phone = localStorage.getItem("sellerphone");
			this.userInfo.avatar = localStorage.getItem("selleravatar");
			this.userInfo.name = localStorage.getItem("sellername");
			this.getUserInfo();
			this.getordercount();
			this.geshopProduct();
		} else {
			this.$router.push({path:'/Login'})
		}
	},
	methods: {
		showbigpic(img){
			this.active= true;
			this.show_pigimg = img;
		},
		getUserInfo() {
			this.$http({
				method: 'get',
				url: 'shopinfo',
				data:{
					id:this.uid
				}
			}).then(res => {
				if(res.code==1){
					if(res.data.logo){
						localStorage.setItem("selleravatar", res.data.logo);
						this.userInfo.avatar = res.data.logo;
					}
					this.userInfo.money = res.data.money;
					this.seller = res.data;
				}
			})
		},
		getordercount(){
			this.$http({
				method: 'get',
				url: 'ordercount',
			}).then(res => {
				if(res.code==1){
					this.ordercount = res.data;
				}
			})
		},
		geshopProduct(){
			this.$http({
				method: 'get',
				url: 'shop_product',
			}).then(res => {
				if(res.code==1){
					this.shop_pro = res.data;
					console.log(this.shop_pro);
				}
			})
		}
	}
}

</script>

<style lang='less' scoped>
.pageheader{
	position: fixed;
	width: 100%;
	z-index: 9999;
}
::v-deep .van-nav-bar {
	height: 48px;
	background: #16151b;
}

::v-deep .van-nav-bar__content {
	padding: 0 10px;
}

::v-deep .van-nav-bar__left {
	padding-left:10px;
	color: #fff;
	font-size: 17px;
	font-weight: bold;
	img{
		width: 30px;
		margin-right: 11px;
	}
}

::v-deep .van-nav-bar__right {
	padding-left:10px;
	img{
		width: 28px;
		margin-right: 10px;
	}
}
.homepage{
	padding-top: 48px;
	padding-bottom: 60px;
	background: #f9f9f9;
}
.moneybox{
	width: 100%;
    height: 209px;
    background-color: #e1e1e1;
    border-radius: 0 0 44px 44px;
    overflow: hidden;
    padding-top: 11px;
    position: relative;
	.totalmoney{
		background: #a8d3ff80;
		width: 370px;
		height: 153px;
		border-radius: 27px;
		padding-left: 33px;
		margin: 0 auto;
		.dian ul{
			display: flex;
			justify-content: flex-end;
			padding: 32px 27px 5px 0;
			li{
				margin-left: 5px;
				width: 8px;
				height: 8px;
				background: #000;
				border-radius: 50%;
			}
		}
		.tit{
			font-size: 17px;
			font-weight: 600;
			color: #000;
			line-height: 22px;
		}
		.num{
			margin-top: 25px;
			font-size: 35px;
			font-weight: 600;
			color: #000;
			line-height: 22px;
		}
	}
}

.swiper-pagination{
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px;
	width: 100%;
}
::v-deep .swiper-pagination-bullet{
	margin-right: 10px;
}
.swiper-pagination-bullet-active{
	background-color: #d43e3f;
}

.visitor{
	margin: 22px 13px 17px;
	padding: 28px 28px 32px 22px;
    height: 241px;
    border-radius: 8px;
    background: url(../images/home/bg1.png);
    background-repeat: no-repeat;
    background-size: cover;
	.visicon1{
		display: flex;
		justify-content: space-between;
		font-size: 26px;
		font-weight: 700;
		color: #1d1e25;
		line-height: 33px;
		span{
			width: 28px;
			height: 28px;
			background-size: cover;
		}
	}
	.visicon2{
		margin-top: 28px;
		display: flex;
		justify-content: space-between;
		div{
			width: 50%;
			span{
				font-size: 22px;
				color: #000;
				font-weight: 700;
				line-height: 22px;
				margin-top: 4px;
			}
			p{
				margin: 0;
				font-size: 14px;
				color: #808d9e;
				line-height: 22px;
			}
		}
	}
	.visicon3{
		margin-top: 22px;
		span{
			font-size: 22px;
			color: #000;
			font-weight: 700;
			line-height: 22px;
			margin-top: 4px;
		}
		p{
			margin: 0;
			font-size: 14px;
			color: #808d9e;
			line-height: 22px;
		}
	}
}
.cartbox{
	margin: 22px 13px 17px;
	display: flex;
	flex-wrap: wrap;
	.divbox{
		width: 50%;
		padding-right: 5px;
	}
	.divbox:last-child{
		padding-right: 0px;
		padding-left: 5px;
	}
	.cart{
		width: 100%;
		background: #a8d3ff;
		color: #000;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding-top: 29px;
		border-radius: 8px;
		height: 218px;
		img{
			width: 68px;
			height: 68px;
		}
		span{
			margin-top: 14px;
			text-align: center;
			font-size: 39px;
			font-weight: 700;
			color: #000;
			line-height: 46px;
			margin-bottom: 5px;
		}
		div{
			font-size: 13px;
			font-weight: 400;
			color: #000;
			line-height: 22px;
		}
	}
	.tuikuan{
		width: 100%;
		background: #a8d3ff;
		color: #000;
		border-radius: 8px;
		flex: 1;
		padding: 12px 0 11px 19px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-bottom: 5px;
		.imgbox{
			display: flex;
			img{
				width: 39px;
				height: 39px;
			}
			span{
				font-size: 26px;
				font-weight: 700;
				color: #000;
				position: relative;
				margin-left: 15px;
				line-height: 38px;
			}
		}
		.str{
			font-size: 13px;
			font-weight: 400;
			color: #000;
			line-height: 42px;
		}
	}
	.say{
		width: 100%;
		background: #a8d3ff;
		color: #000;
		border-radius: 8px;
		flex: 1;
		padding: 12px 0 11px 19px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-top: 10px;
		.imgbox{
			display: flex;
			img{
				width: 39px;
				height: 39px;
			}
			span{
				font-size: 26px;
				font-weight: 700;
				color: #000;
				position: relative;
				margin-left: 15px;
				line-height: 38px;
			}
		}
		.str{
			font-size: 13px;
			font-weight: 400;
			color: #000;
			line-height: 42px;
		}
	}
}
.sharebox{
	margin: 22px 13px 17px;
    height: 94px;
    padding: 19px 38px;
    background-image: url(../images/home/bg_share.png);
    background-repeat: no-repeat;
    background-size: cover;
	border-radius: 8px;
}
.orderbox{
	padding: 22px 13px;
    margin: 17px 11px;
	background: #fff;
	.ordertit{
		font-size: 26px;
		font-weight: 700;
		color: #1d1e25;
		line-height: 33px;
		padding: 6px 0 13px;
	}
	.ordercon{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		.order{
			overflow: hidden;
			width: 48%;
			height: 110px;
			margin-top: 11px;
			display: flex;
			padding: 8px 8px 15px 26px;
			flex-direction: column;
			justify-content: space-between;
			border-radius: 6px;
			background-color: #fff;
			box-shadow: 0 0 8px 0 rgba(0, 0, 0, .1);
			border-radius: 8px;
			.img{
				width: 100%;
				height: 17px;
				text-align: right;
				img{
					width: 17px;
					height: 17px;
				}
			}
			.num{
				margin: 0 24px 0 0;
				font-size: 24px;
				font-weight: 600;
				color: #000;
				line-height: 22px;
			}
			.tit{
				width: 100%;
				margin-top: 17px;
				line-height: 22px;
				word-break: keep-all;
				font-size: 13px;
				color: #808d9e;
			}
		}
	}
}
.storebox{
	margin: 0 13px;
	.pro{
		display: flex;
		height: 117px;
		padding: 22px 26px 17px;
		margin-bottom: 13px;
		background: #fff;
		border-radius: 8px;
		.img{
			width: 52px;
			height: 52px;
			img{
				width: 100%;
				height: 100%;
			}
		}
		.des{
			margin-left: 26px;
			span{
				font-size: 33px;
				font-weight: 700;
				color: #000;
				line-height: 46px;
			}
			div{
				font-size: 15px;
				color: #000;
				line-height: 24px;
				margin-top: 7px;
			}
		}
	}
}
.settingbox{
	margin: 0 13px;
	padding: 28px 11px 22px;
    background: #fff;
    border-radius: 8px;
	.settitle{
		font-size: 26px;
		font-weight: 700;
		color: #1d1e25;
		line-height: 33px;
	}
	.setcon{
		display: flex;
		justify-content: space-between;
		.item{
			display: flex;
			flex-direction: column;
			width: 48%;
			height: 193px;
			overflow: hidden;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 19px 5px 14px;
			background: #fff;
			box-shadow: 0 0 16px 0 rgba(0, 0, 0, .1);
			border-radius: 22px;
			margin-top: 28px;
			img{
				width: 61px;
				height: 61px;
			}
			span{
				font-size: 17px;
				font-weight: 600;
				color: #000;
				line-height: 22px;
				margin-top: 13px;
			}
			div{
				width: 133px;
				height: 37px;
				font-size: 13px;
				margin-top: 16px;
				border-radius: 4px;
				background: #a8d3ff;
				text-align: center;
				line-height: 37px;
			}
		}
		
	}
}
.productbox{
	margin: 0 13px;
	padding-top: 27px;
	.protitle{
		text-align: center;
		font-size: 22px;
		padding: 5px 0 4px;
		font-weight: 700;
		color: #1d1e25;
		line-height: 22px;
	}
	.list{
		width: 378px;
		height: 128px;
		margin: 17px auto 0;
		background: #fff;
		border-radius: 6px;
		overflow: hidden;
		display: flex;
		.img{
			width: 128px;
			height: 128px;
			img{
				width: 100%;
				height: 100%;
			}
		}
		.con{
			flex: 1;
			padding: 13px 15px 15px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.price{
				font-size: 22px;
				font-weight: 700;
				color: #000;
				line-height: 22px;
			}
		}
	}
}
.shoppic{
	width: 300px;
	height: 250px;
	display:flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	background: #fff;
	.img{
		width: 100%;
		height: 200px;
		img{
			width:100%;
			height: 100%;
		}
	}

}
</style>
