<template>
	<div class="home-container">
		<div class="pageheader">
			<van-nav-bar class="nav-bar">
				<template #left>
					<van-icon name="arrow-left" color="#000000" @click="back()" />
					<span>{{ $t('my.recharge') }}</span>
				</template>
			</van-nav-bar>
		</div>
		<div class="homepage">
			<div>
				<div style="font-weight: 600;line-height: 30px;"><span style="color: #f00;">*</span>{{ $t('my.money') }}</div>
				<div>
					<van-field v-model="money" class="input"></van-field>
				</div>
				<div style="font-weight: 600;line-height: 30px;margin-top: 17px;"><span style="color: #f00;">*</span>{{ $t('recharge.rec_code') }}</div>
				<div>
					<van-field v-model="rec_code" class="input"></van-field>
				</div>
				<div style="color: #f00;font-size: 13px;">{{ $t('recharge.tip') }}</div>
			</div>
		</div>	
		
		<div class="bottombtn" @click='do_recharge()'>
			<div>{{ $t('common.okbtn') }}</div>
		</div>	
	</div>
</template>

<script>
export default {
	data() {
		return {
			active:false,
			seller:[],
			uid:0,
			money:0,
			rec_code:'',
			payment_method:''
		};
	},
	mounted() {
		
	},
	created() {
		if (localStorage.getItem('access_token')) {
			this.uid = localStorage.getItem("sellerid");
			this.payment_method = this.$route.query.bank;
			this.getUserInfo();
		} else {
			this.$router.push({path:'/Login'})
		}
	},
	methods: {
		back(){
			this.$router.push({path:'/wallet'})
		},
		getUserInfo() {
			this.$http({
				method: 'get',
				url: 'shopinfo',
				data:{
					id:this.uid
				}
			}).then(res => {
				if(res.code==1){
					this.seller = res.data;
				}
			})
		},
		do_recharge(){
			this.$http({
				method: 'post',
				url: 'do_recharge',
				data:{
					money:this.money,
					rec_code:this.rec_code,
					payment_method:this.payment_method,
				}
			}).then(res => {
				if(res.code==1){
					this.$toast(this.$t("setting.uploadSuccess"));
					this.$router.push({path:'/wallet'})
				}
			})
		}
	}
}

</script>

<style lang='less' scoped>
.pageheader{
	position: fixed;
	width: 100%;
	z-index: 9999;
}
::v-deep .van-nav-bar {
	height: 48px;
	background: #fff;
	box-shadow: 0 1px 6px #ccc;
}

::v-deep .van-nav-bar__content {
	padding: 0 10px;
}

::v-deep .van-nav-bar__left {
	padding-left:10px;
	color: #000;
	font-size: 17px;
	font-weight: bold;
}
.homepage{
	padding: 103px 17px 60px;
	background: #f9f9f9;
}
.input{
	border: solid 1px #ccc;
}
.bottombtn{
	position: fixed;
    z-index: 3;
    left: 0;
    right: 0;
    bottom: 16px;
    display: flex;
    justify-content: space-around;
    width: 100%;
	div{
		width: 90%;
		height: 50px;
		background: #000;
		color: #fff;
		border-radius: 6px;
		line-height: 50px;
		text-align: center;
	}
}
</style>
