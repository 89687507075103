<template>
	<div class="home-container">
		<div class="pageheader">
			<van-nav-bar class="nav-bar">
				<template #left>
					<van-icon name="arrow-left" color="#000000" @click="back()" />
					<span>{{ $t('index.viewstitle') }}</span>
				</template>
			</van-nav-bar>
		</div>
		<div class="homepage">
			<div class="buygg">
				<div class="tit">{{ $t('index.views_ggtit') }}</div>
				<div class="des">{{ $t('index.views_ggdes') }}</div>
				<div class="btn">{{ $t('index.views_ggbtn') }}</div>
			</div>
			<div class="today">
				<div class="tit">{{ $t('index.todayvisi') }}</div>
				<span>{{ seller.shopviews.day }}</span>
			</div>
			<div class="day7box">
				<div class="tit">
					<span>{{ $t('index.visi7') }}</span>
					<span>{{ seller.shopviews.day7 }}</span>
				</div>
				<div>

				</div>
			</div>
			<div class="day7box">
				<div class="tit">
					<span>{{ $t('index.visi30') }}</span>
					<span>{{ seller.shopviews.day30 }}</span>
				</div>
				<div>
					
				</div>
			</div>
		</div>	
	</div>
</template>

<script>
export default {
	data() {
		return {
			active:false,
			seller:[],
			uid:0,
			
		};
	},
	mounted() {
		
	},
	created() {
		if (localStorage.getItem('access_token')) {
			this.uid = localStorage.getItem("sellerid");
			this.getUserInfo();
		} else {
			this.$router.push({path:'/Login'})
		}
	},
	methods: {
		back(){
			this.$router.push({path:'/Home'})
		},
		getUserInfo() {
			this.$http({
				method: 'get',
				url: 'shopinfo',
				data:{
					id:this.uid
				}
			}).then(res => {
				if(res.code==1){
					this.seller = res.data;
				}
			})
		}
	}
}

</script>

<style lang='less' scoped>
.pageheader{
	position: fixed;
	width: 100%;
	z-index: 9999;
}
::v-deep .van-nav-bar {
	height: 48px;
	background: #fff;
	box-shadow: 0 1px 6px #ccc;
}

::v-deep .van-nav-bar__content {
	padding: 0 10px;
}

::v-deep .van-nav-bar__left {
	padding-left:10px;
	color: #000;
	font-size: 17px;
	font-weight: bold;
}
.homepage{
	padding: 73px 17px 60px;
	background: #f9f9f9;
}
.buygg{
	width: 100%;
    height: 145px;
    padding: 15px 13px 11px 22px;
    background-image: url(../images/home/bg_view_buygg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
	.tit{
		font-size:14px;
		font-weight: 600;
		color: #000;
		line-height: 22px;
	}
	.des{
		height: 62px;
		width: 236px;
		font-size: 11px;
		color: #000;
		line-height: 15px;
		text-align: left;
		word-break: keep-all;
		word-wrap: normal;
	}
	.btn{
		width: 97px;
		height: 27px;
		text-align: center;
		line-height: 27px;
		font-size: 13px;
		font-weight: 600;
		border-radius: 6px;
		background:#a8d3ff;
	}
}
.today{
	width: 100%;
    height: 86px;
    margin-top: 22px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    box-shadow: 0 0 11px 0 rgba(0, 0, 0, .15);
    border-radius: 6px;
	.tit{
		font-size: 13px;
		color: #000;
		line-height: 22px;
	}
	span{
		margin-top: 6px;
		font-size: 22px;
		font-weight: 600;
		color: #000;
		line-height: 22px;
	}
}
.day7box{
	width: 100%;
    margin-top: 22px;
    height: 256px;
    background-color: #fff;
    box-shadow: 0 0 11px 0 rgba(0, 0, 0, .15);
    border-radius: 6px;
	.tit{
		display: flex;
		justify-content: space-between;
		padding: 17px 20px 6px 14px;
		font-size: 15px;
		font-weight: 600;
		color: #000;
		line-height: 22px;
	}
}
</style>
