<template>
	<div class="home-container">
		<div class="pageheader">
			<van-nav-bar class="nav-bar">
				<template #left>
					<van-icon name="arrow-left" color="#000" @click="back()" />
					<span>{{ $t('my.menu1') }}</span>
				</template>
			</van-nav-bar>
		</div>
		<div class="homepage">
			<div class="menu">
				<div class="menuitem">
					<div class="left">
						<div class="img"><img :src="avatar" /></div>
					</div>
					<div class="right">
						<van-icon name="arrow" />
					</div>
				</div>
				<div class="menuitem" @click="active_name=true">
					<div class="left"><span>{{ $t('setting.sname') }}</span></div>
					<div class="right">
						<div style="margin-right: 17px;font-size: 14px;">{{ us_name }}</div>
						<van-icon name="arrow" />
					</div>
				</div>
				<div class="menuitem" @click="active_phone=true">
					<div class="left"><span>{{ $t('setting.sphone') }}</span></div>
					<div class="right">
						<div style="margin-right: 17px;font-size: 14px;">{{ us_phone }}</div>
						<van-icon name="arrow" />
					</div>
				</div>
				<div class="menuitem" @click="active_pwd=true">
					<div class="left"><span>{{ $t('setting.spwd') }}</span></div>
					<div class="right">
						<van-icon name="arrow" />
					</div>
				</div>
				<div class="menuitem" @click="active_pay=true">
					<div class="left"><span>{{ $t('setting.spay') }}</span></div>
					<div class="right">
						<van-icon name="arrow" />
					</div>
				</div>
			</div>
		</div>
		<van-popup v-model="active_name">
			<div class="popupbox">
				<div class="popuptit">{{ $t('setting.snametit') }}{{ $t('setting.sname') }}</div>
				<div class="sel_lang">
					<div class="tit">{{ $t('setting.sname') }}</div>
					<van-field v-model="us_name" class="input"></van-field>
				</div>
				<div class="btnlist">
					<div @click="active_name=false">{{ $t('common.qxbtn') }}</div>
					<div @click="setusinfo()">{{ $t('common.okbtn') }}</div>
				</div>
			</div>
		</van-popup>
		<van-popup v-model="active_phone">
			<div class="popupbox">
				<div class="popuptit">{{ $t('setting.snametit') }}{{ $t('setting.sphone') }}</div>
				<div class="sel_lang">
					<div class="tit">{{ $t('setting.sphone') }}</div>
					<van-field v-model="us_phone" class="input"></van-field>
				</div>
				<div class="btnlist">
					<div @click="active_phone=false">{{ $t('common.qxbtn') }}</div>
					<div @click="setusinfo()">{{ $t('common.okbtn') }}</div>
				</div>
			</div>
		</van-popup>
		<van-popup v-model="active_pwd">
			<div class="popupbox">
				<div class="popuptit">{{ $t('setting.login_pwd_tip') }}</div>
				<div class="sel_lang">
					<div style="margin-bottom:17px;">
						<div class="tit">{{ $t('setting.old_pwd') }}</div>
						<van-field v-model="loginpwd.old" class="input"></van-field>
					</div>
					<div style="margin-bottom:17px;">
						<div class="tit">{{ $t('setting.new_pwd') }}</div>
						<van-field v-model="loginpwd.new" class="input"></van-field>
					</div>
					<div>
						<div class="tit">{{ $t('setting.renew_pwd') }}</div>
						<van-field v-model="loginpwd.renew" class="input"></van-field>
					</div>
					
				</div>
				<div class="btnlist">
					<div @click="active_pwd=false">{{ $t('common.qxbtn') }}</div>
					<div @click="editpwd()">{{ $t('common.okbtn') }}</div>
				</div>
			</div>
		</van-popup>
		
		<van-popup v-model="active_pay">
			<div class="popupbox">
				<div class="popuptit">{{ $t('setting.snametit') }}{{ $t('setting.sktype') }}</div>
				<div class="sel_lang">
					<div style="margin-bottom:17px;display: flex;justify-content:space-between;align-items: center;">
						<div class="tit">{{ $t('setting.usdttip') }}</div>
						<van-switch v-model="paysetdata.isusdt" size="20" />
					</div>
					<div style="margin-bottom:17px;" v-if="paysetdata.isusdt">
						<div class="tit">{{ $t('setting.usdtaddr') }}</div>
						<van-field v-model="paysetdata.usdtaddre" class="input"></van-field>
					</div>
					<div style="margin-bottom:17px;display: flex;justify-content:space-between;align-items: center;">
						<div class="tit">{{ $t('setting.bankpay') }}</div>
						<van-switch v-model="paysetdata.isbank" size="20" />
					</div>
					<div v-if="paysetdata.isbank">
						<div style="margin-bottom:17px;">
							<div class="tit">{{ $t('setting.band_name') }}</div>
							<van-field v-model="paysetdata.bank_name" class="input"></van-field>
						</div>
						<div style="margin-bottom:17px;">
							<div class="tit">{{ $t('setting.username') }}</div>
							<van-field v-model="paysetdata.username" class="input"></van-field>
						</div>
						<div>
							<div class="tit">{{ $t('setting.band_account') }}</div>
							<van-field v-model="paysetdata.bank_account" class="input"></van-field>
						</div>
					</div>
				</div>
				<div class="btnlist">
					<div @click="active_pay=false">{{ $t('common.qxbtn') }}</div>
					<div @click="edit_payinfo()">{{ $t('common.okbtn') }}</div>
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
export default {
	data() {
		return {
			active_avatar:false,
			active_name:false,
			active_phone:false,
			active_pwd:false,
			active_pay:false,
			seller:[],
			uid:0,
			lang:'zh_cn',
			us_name:'',
			us_phone:'',
			filepath:[],
			avatar:'',
			selavatar:0,
			loginpwd:{
				old:'',
				new:'',
				renew:''
			},
			paysetdata:{
				isusdt:false,
				isbank:false,
				usdtaddre:'',
				bank_name:'',
				username:'',
				bank_account:''
			}
		};
	},
	mounted() {
		this.lang = localStorage.getItem("lang") || 'zh_cn';
	},
	created() {
		if (localStorage.getItem('access_token')) {
			this.uid = localStorage.getItem("sellerid");
			this.getUserInfo();
		} else {
			this.$router.push({path:'/Login'})
		}
	},
	methods: {
		back(){
			this.$router.push({path:'/mine'})
		},
		getUserInfo() {
			this.$http({
				method: 'get',
				url: 'shopinfo',
				data:{
					id:this.uid
				}
			}).then(res => {
				if(res.code==1){
					this.seller = res.data;
					this.avatar = this.seller.avatar;
					this.us_name = this.seller.usname;
					this.us_phone = this.seller.uphone;
					this.paysetdata.isusdt = this.seller.usdt_payment_status==1?true:false;
					this.paysetdata.isbank = this.seller.bank_payment_status==1?true:false;
					this.paysetdata.usdtaddre = this.seller.usdt_address;
					this.paysetdata.bank_name = this.seller.bank_name;
					this.paysetdata.username = this.seller.bank_acc_name;
					this.paysetdata.bank_account = this.seller.bank_acc_no;
				}
			})
		},
		setusinfo(){
			this.$http({
				method: 'post',
				url: 'do_usinfo',
				data:{
					uname:this.us_name,
					uphone:this.us_phone
				}
			}).then(res => {
				if(res.code==1){
					this.$toast(this.$t("setting.uploadSuccess"));
					this.active_name=false;
					this.active_phone=false;
				}
			})
		},
		editpwd(){
			if(this.loginpwd.old==''){
				this.$toast(this.$t("setting.old_pwd_tip"));
				return;
			}
			if(this.loginpwd.new==''){
				this.$toast(this.$t("setting.new_pwd_tip"));
				return;
			}
			if(this.loginpwd.renew==''){
				this.$toast(this.$t("setting.new_again_tip"));
				return;
			}
			if(this.loginpwd.new!=this.loginpwd.renew){
				this.$toast(this.$t("setting.pwd_error"));
				return;
			}
			if(this.loginpwd.new==this.loginpwd.old){
				this.$toast(this.$t("setting.pwd_oldnew"));
				return;
			}
			this.$http({
				method: 'post',
				url: 'do_loginpwd',
				data:this.loginpwd
			}).then(res => {
				if(res.code==1){
					this.$toast(this.$t("setting.uploadSuccess"));
					this.active_pwd=false;
				}
				else{
					this.$toast(this.$t(res.msg));
				}
			})
		},
		edit_payinfo(){
			if(this.paysetdata.isusdt && this.paysetdata.usdtaddre==''){
				this.$toast(this.$t("setting.usdtempty"));
				return;
			}
			if(this.paysetdata.isbank){
				if(this.paysetdata.bank_name==''){
					this.$toast(this.$t("setting.band_name_tip"));
					return;
				}
				if(this.paysetdata.username==''){
					this.$toast(this.$t("setting.请输入账户名称"));
					return;
				}
				if(this.paysetdata.bank_account==''){
					this.$toast(this.$t("setting.请输入账号"));
					return;
				}
				
			}
			this.$http({
				method: 'post',
				url: 'do_editbankinfo',
				data:this.paysetdata
			}).then(res => {
				if(res.code==1){
					this.$toast(this.$t("setting.uploadSuccess"));
					this.active_pay=false;
				}
				else{
					this.$toast(this.$t(res.msg));
				}
			})
		}
	}
}

</script>

<style lang='less' scoped>
.pageheader{
	position: fixed;
	width: 100%;
	z-index: 1999;
}
::v-deep .van-nav-bar {
	height: 48px;
	background: #fff;
	box-shadow: 0 1px 6px #ccc;
}

::v-deep .van-nav-bar__content {
	padding: 0 10px;
}

::v-deep .van-nav-bar__left {
	padding-left:10px;
	color: #000;
	font-size: 17px;
	font-weight: bold;
}

.homepage{
	padding: 73px 17px 60px;
	background: #f9f9f9;
}
img{
	width: 100%;
	height: 100%;
}
.menu{
	padding-top: 25px;
	.menuitem{
		display: flex;
		justify-content: space-between;
		padding-bottom: 33px;
		.left{
			display: flex;
			align-items: center;
			.img{
				width: 72px;
				height: 72px;
				margin-right: 15px;
			}
		}
		.right{
			display: flex;
			align-items: center;
		}
	}
}

::v-deep .van-popup {
	width:80%;
}
.popupbox{
	padding: 22px;
	.popuptit{
		padding: 0 22px;
		margin-bottom: 22px;
		font-size: 18px;
		font-weight: 600;
		color: #000;
		line-height: 22px;
		text-align: center;
	}
	.btnlist{
		display: flex;
		justify-content: space-between;
		padding-top: 22px;
		div{
			display: flex;
			align-items: center;
			justify-content: center;
			width: 45%;
			height: 50px;
			border-radius: 6px;
			border: 1px solid #000;
			font-size: 15px;
			font-weight: 600;
			color: #000;
		}
		div:last-child{
			color: #fff;
			background: #000;
		}
	}
}
.sel_lang{
	.mavatar{
		text-align: center;
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		img{
			width: 90px;
			height: 90px;
			margin-bottom: 10px;
		}
	}
}

.input{
	border: solid 1px #dadbde;
	border-radius: 4px;
	height: 38px;
	padding: 6px 9px;
}
::v-deep .van-switch {
	background: #ccc;
}
::v-deep .van-switch--on {
	background: #000;
}
</style>
