import Vue from 'vue'
import VueRouter from 'vue-router'
//首页
import Login from '../pages/login/index.vue'/* 登录 */
import Home from '../pages/home/index.vue'/* 首页 */
import Views from '../pages/home/views.vue'/* 访客统计 */
import Product from '../pages/mine/product.vue'/* 商品 */
import Reviews from '../pages/mine/reviews.vue'/* 商品评论 */
import Orders from '../pages/mine/orders.vue'/* 订单 */
import Mine from '../pages/mine/index.vue'/* 我的 */
import Wallet from '../pages/mine/wallet.vue'/* 钱包 */
import Financ from '../pages/mine/financ.vue'/* 财务 */
import Share from '../pages/mine/share.vue'/* 推广 */
import Setting from '../pages/mine/Setting.vue'/* 设置 */
import Setname from '../pages/mine/Setname.vue'/* 店铺设置 */
import Recharge from '../pages/mine/Recharge.vue'/* 店铺设置 */



Vue.use(VueRouter)
const routes = [
    {path:'/',redirect:'/Home',component:Home,meta:{title:'首页'}},
    {path:'/Home',name:'home',component:Home,meta:{title:'首页'}},
    {path:'/Views',name:'views',component:Views,meta:{title:'访客统计'}},
    {path:'/Product',name:'product',component:Product,meta:{title:'商品'}},
    {path:'/Reviews',name:'reviews',component:Reviews,meta:{title:'商品评论'}},
    {path:'/Orders',name:'orders',component:Orders,meta:{title:'订单'}},
    {path:'/Mine',name:'mine',component:Mine,meta:{title:'我的'}},
    {path:'/Wallet',name:'wallet',component:Wallet,meta:{title:'钱包'}},
    {path:'/Financ',name:'financ',component:Financ,meta:{title:'财务报表'}},
    {path:'/Share',name:'share',component:Share,meta:{title:'推广'}},
    {path:'/Setting',name:'Setting',component:Setting,meta:{title:'设置'}},
    {path:'/Setname',name:'Setname',component:Setname,meta:{title:'店铺设置'}},
    {path:'/Login',name:'login',component:Login,meta:{title:'登录'}},
    {path:'/Recharge',name:'Recharge',component:Recharge,meta:{title:'充值'}},


];

//生成路由实例
const router = new VueRouter({
    routes
})
router.beforeEach((to,from,next) => {         //修改标题路由配置加上这个
    document.title = to.matched[0].meta.title
    next()
})

export default router