<template>
	<div class="container page">

		<div class="bg-wrapper">
			<div class="headcon">
				<div class="lang">
					<img :src="langflag" @click="sellang=true" />
					<span class="to-icon-arrow-down" @click="sellang=true"></span>
				</div>
				<div class="alink">
					<span class="to-icon-cart"></span>
					<span style="margin: 0 5px;">{{ $t("login.login_switch_user") }}</span>
					<span class="to-icon-arrow-right"></span>
				</div>
				<van-popup v-model="sellang" get-container=".headcon" position="top">
					<div class="lang_box">
						<ul>
							<li @click="changeLang('en_us','en')">English</li>
							<li @click="changeLang('zh_cn','cn')">中文</li>
							<li @click="changeLang('zh_hk','hk')">繁体中文</li>
						</ul>
					</div>
				</van-popup>
			</div>
			<div class="webinfo">
				<div class="logo"><img :src="this.$store.getters.getBaseInfo.site_icon" style="width:94px;" /></div>
				<span>{{ $t("login.login_tip") }}</span>
			</div>
			<div class="login">
				<div class="wrapper">
					
					<div class="loginForm">
						<div class="inputfield">
							<span>{{ $t('login.login_user') }}</span>
							<van-field v-model="username" class="input" :placeholder="$t('login.login_user_tip')">
								<van-icon class="to-icon-arrow-mail" class-prefix="icon"  slot="left-icon" name="yonghu"></van-icon>
							</van-field>
						</div>
						<div class="inputfield">
							<span>{{ $t('login.login_pwd') }}</span>
							<van-field v-model="password" :type="passwordType" class="input" :placeholder="$t('login.login_pwd_tip')">
								<van-icon class="to-icon-arrow-lock" class-prefix="icon"  slot="left-icon" name="yonghu"></van-icon>
								<template slot="right-icon">
									<van-icon
									:name="passwordType === 'password' ? 'closed-eye' : 'eye-o'"
									@click="switchPasswordType"
									/>
								</template>
							</van-field>
						</div>
						<div class="remem" style="height: 40px;">
							<van-checkbox v-model="remember" shape="square" icon-size="14px">{{ $t('login.login_remember') }}</van-checkbox>
						</div>
						<div class="forg" style="height: 30px;line-height: 30px; text-align: right;">{{ $t('login.login_forget') }}</div>
						<van-button class="login-btn" type="primary" size="normal" @click="doLogin()">{{ $t('login.login_btn') }}</van-button>
					</div>
				</div>
				
				<div class="register-text">
					{{ $t('login.login_contact') }}
				</div>
			</div>
		</div>
		<!-- switch lang box -->
		<div v-show="tipshow" class="van-overlay" style="z-index: 15; animation-duration: 0.2s;"></div>
		<div v-show="tipshow" class="server-dialog">
			<ul>
				<li>温馨提示</li>
				<li>联系数据老师激活权限</li>
				<li @click="tipshow = false">确定</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	model: {
		prop: "inputValue",
		event: "input",
	},
	props: {
		/**
		 * 当前输入的值
		 */
		inputValue: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			username: "",
			lang: 'en_us',
			password: this.inputValue,
			passwordType: "password",
			tipshow:false,
			remember:0,
			langflag:'',
			sellang:false
		};
	},
	mounted() {
		// localStorage.setItem("lang", 'ms_my');
		this.lang = localStorage.getItem("lang") || 'zh_cn';
	},
	methods: {
		changeLang(lang,flagname) {
			this.langflag = require('../images/flags/'+flagname+'.png');
			this.$i18n.locale = lang;
			localStorage.setItem("lang", lang);
			localStorage.setItem("langflag", flagname);
			this.sellang=false;
		},
		switchPasswordType() {
			this.passwordType =
				this.passwordType === "password" ? "text" : "password";
		},
		back() {
			return window.history.back();
		},
		toRegister() {
			this.$router.push("Register");
		},
		doLogin() {
			if (
				this.username === "" ||
				this.username === null ||
				this.username === undefined
			) {
				this.$toast(this.$t("auth.username_place"));
				return false;
			}
			if (
				this.password === "" ||
				this.password === null ||
				this.password === undefined
			) {
				this.$toast(this.$t("auth.pwd_place"));
				return false;
			}
			this.$http({
				url: "login",
				method: "post",
				data: {
					account: this.username,
					password: this.password
				}
			}).then((res) => {
				if (res.code==1) {
					localStorage.setItem("access_token", res.data.userinfo.token);
					localStorage.setItem("sellerid", res.data.userinfo.id);
					localStorage.setItem("sellername", res.data.userinfo.name);
					localStorage.setItem("selleremail", res.data.userinfo.email);
					localStorage.setItem("selleravatar", res.data.userinfo.avatar);
					localStorage.setItem("sellerphone", res.data.userinfo.phone);
					this.$toast.success(res.msg);
					this.$router.push("/");
				} else {
					this.$toast(res.message);
				}
			});
		},
	},
	created() {
		this.langflag = require('../images/flags/cn.png');
		if(localStorage.getItem("langflag")){
			this.langflag = require('../images/flags/'+localStorage.getItem("langflag")+'.png');
		}
		if (localStorage.getItem("token")) {
			return window.history.back();
		}
	},
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.container{
	.bg-wrapper{
		background: url(../images/login/bg.png) no-repeat center top;
		background-size: contain;
	}
}

::v-deep .van-popup {
	position: absolute;
	top:45px;
	left: 20px;
	background: none;
	max-height: 300px;
	width: auto;
}
.headcon{
	height: 50px;
	display: flex;
    justify-content: space-between;
    margin-top: 20px;
    align-items: center;
		position: relative;
	.lang{
		height: 38px;
		display: flex;
		padding: 5px 8px;
		border: solid 1px #000;
		border-radius: 8px;
		margin-left: 20px;
		img{
			height: 26px;
			width: 26px;
			border-radius: 13px;
		}
		span{
			display: block;
			width: 13px;
			height: 10px;
			margin: 8px;
			background-size: cover;
		}
	}
	.alink{
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 30px;
		padding: 0 11px;
		-webkit-text-decoration-line: none;
		text-decoration-line: none;
		border-radius: 18px 0 0 18px;
		background-color: #a8d3ff !important;
		.to-icon-cart{
			display: block;
			width: 16px;
			height: 13px;
			background-size: contain;
		}
		.to-icon-arrow-right{
			display: block;
			width: 17px;
			height: 17px;
			background-size: contain;
		}
	}
	.lang_box{
		background: #fff;
		width: 150px;
		border-radius: 10px;
		ul{
			padding: 10px;
			li{
				line-height: 40px;
				border-bottom: solid 1px #ccc;
				display: flex;
			}
		}
	}
}
.webinfo{
	display: flex;
    margin: 30px;
    height: 94px;
	.logo{
		align-items: center;
		display: flex;
		justify-content: space-between;
	}
	span{
		width: 40%;
		font-size: 18px;
		margin-left: 10px;
		font-weight: 700;
	}
}
.inputfield{
	margin-bottom: 20px;
	span{
		font-weight: 700;
	}
}
.to-icon-arrow-mail{
	display: block;
	width: 30px;
	height: 25px;
	background-size: contain;
	margin-top: 8px;
}
.to-icon-arrow-lock{
	display: block;
	width: 30px;
	height: 25px;
	background-size: contain;
	margin-top: 8px;
}
.login {
	position: relative;
}

.login .wrapper .loginForm .input {
	padding: 10px 20px;
    margin-top: 10px;
    text-align: center;
    line-height: 30px;
    font-size: 16px;
    color: #fff;
    background-color: #fff;
	overflow:unset;
	height: 64px;
	border-radius: 32px;
}

::v-deep .van-cell::after {
	border: none;
}
::v-deep .van-field__control {
	color:#000;
	height: 40px;
	text-align: left;
}

::v-deep .van-field__right-icon .van-icon {
	font-size: 22px;
}

::v-deep .van-icon {
	font-size: 16px;
}
.login .wrapper .loginForm {
	padding: 0 30px;
}

.login .wrapper .loginForm .login-btn {
	margin-top: 20px;
    width: 100%;
    height: 60px;
    color: #000;
    background: #a8d3ff;
    font-size: 16px;
    font-weight: bolder;
	border-radius: 30px;
    border: none;
}
.login .register-text {
	text-align: center;
	width: 100%;
	margin-top: 80px;
	color: #a8d3ff;
}

::v-deep .van-checkbox {
	height: 30px;
}
::v-deep .van-checkbox__icon {
	height: auto;
}














.login .wrapper .loginForm .reset-text {
	margin: 30px 15px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.login .wrapper .loginForm .reset-text span {
	color: #fff;
	font-size: 25px;
	font-weight: 500;
	line-height: 15px;
}


.login .wrapper .loginForm .register-text span {
	color: #fff;
	font-size: 25px;
	font-weight: 500;
	line-height: 20px;
}


</style>
