<template>
	<div class="home-container">
		<div class="pageheader">
			<van-nav-bar class="nav-bar">
				<template #left>
					<van-icon name="arrow-left" color="#000000" @click="back()" />
					<span>{{ $t('product.reviews') }}</span>
				</template>
			</van-nav-bar>
		</div>
		<div class="homepage">
					
			<div class="reviewslist">
				<div class="item" v-for="(item,ind) in shop_pro" :key="ind">
					<div class="avatar"><img src="../images/avatar.png" alt=""></div>
					<div class="conbox">
						<div class="name">{{ item.usname }}</div>
						<div class="ratstar">
							<van-icon name="star" color="#fac22f" />
							<van-icon name="star" color="#fac22f" />
							<van-icon name="star" color="#fac22f" />
							<van-icon name="star" color="#fac22f" />
							<van-icon name="star" color="#fac22f" />
						</div>
						<div class="con">{{ item.comment }} </div>
						<div class="pro">
							<div class="pimg"><img :src="item.image" alt=""></div>
							<div class="pcon">{{ item.proname }} </div>
						</div>
						<div class="time">{{ item.created_at }} </div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			shop_pro:[],
			show_pigimg:'',
			active:false,
			seller:[],
			uid:0,
			
		};
	},
	mounted() {
		
	},
	created() {
		if (localStorage.getItem('access_token')) {
			this.uid = localStorage.getItem("sellerid");
			this.getUserInfo();
			this.getshowreviews();
		} else {
			this.$router.push({path:'/Login'})
		}
	},
	methods: {
		
		back(){
			this.$router.push({path:'/Product'})
		},
		getUserInfo() {
			this.$http({
				method: 'get',
				url: 'shopinfo',
				data:{
					id:this.uid
				}
			}).then(res => {
				if(res.code==1){
					this.seller = res.data;
				}
			})
		},
		getshowreviews(){
			this.$http({
				method: 'get',
				url: 'showreviews'
			}).then(res => {
				if(res.code==1){
					this.shop_pro = res.data;
				}
			})
		}
	}
}

</script>

<style lang='less' scoped>
.pageheader{
	position: fixed;
	width: 100%;
	z-index: 9999;
}
::v-deep .van-nav-bar {
	height: 48px;
	background: #fff;
	box-shadow: 0 1px 6px #ccc;
}

::v-deep .van-nav-bar__content {
	padding: 0 10px;
}

::v-deep .van-nav-bar__left {
	padding-left:10px;
	color: #000;
	font-size: 17px;
	font-weight: bold;
}
.homepage{
	padding: 73px 17px 60px;
	background: #f9f9f9;
}
.reviewslist{
	.item{
		height: 315px;
		border-radius: 6px;
		padding: 22px 16px 17px 22px;
		margin-bottom: 17px;
		display: flex;
		background-color: #fff;
		.avatar{
			width: 37px;
			height: 37px;
			img{
				width: 100%;
				height: 100%;
			}
		}
		.conbox{
			padding-left: 11px;
			flex: 1;
			.name{
				font-size: 13px;
				color: #000;
				line-height: 20px;
			}
			.ratstar{
				display: flex;
				flex-direction: row;
				align-items: center;
				margin: 0;
				padding: 0;
				touch-action: none;
			}
			.con{
				height: 110px;
				font-size: 13px;
				margin-top: 15px;
				color: #989898;
				line-height: 22px;
			}
			.pro{
				display: flex;
				margin-top: 16px;
				.pimg{
					width: 66px;
					height: 66px;
					img{
						width: 100%;
						height: 100%;
					}
				}
				.pcon{
					margin-left: 11px;
					flex: 1;
					height: 44px;
					font-size: 14px;
					color: #000;
					line-height: 22px;
				}
			}
			.time{
				margin-top: 13px;
				font-size: 11px;
				color: #989898;
				line-height: 22px;
				text-align: right;
			}
		}
	}
}
</style>
