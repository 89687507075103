<template>
	<div class="home-container">
		<div class="pageheader">
			<van-nav-bar class="nav-bar">
				<template #left>
					<span>{{ $t('foorter.my') }}</span>
				</template>
			</van-nav-bar>
		</div>
		<div class="homepage">
			<div class="avatarbox">
				<div class="img"><img :src="seller.logo" /></div>
				<div class="info">
					<div class="name">{{ seller.name }}</div>
					<div class="account">{{ seller.email }}</div>
					<div class="rating">
						<div class="fen">
							<van-icon name="star" color="#fac22f" />
							<span>{{ seller.rating }}</span>
						</div>
						<div class="yirz rz" v-if="seller.verification_status==1">{{ $t('my.renz') }}</div>
						<div class="yirz" v-else>{{ $t('my.unrenz') }}</div>
					</div>
				</div>
			</div>
			<div class="menu">
				<div class="menuitem" @click="$router.push({ path: '/Setting' })">
					<div class="left">
						<div class="ico"><img src="../images/user/ico_2.png" /></div>
						<span>{{ $t('my.menu1') }}</span>
					</div>
					<div class="right">
						<van-icon name="arrow" />
					</div>
				</div>
				<div class="menuitem" @click="active_lang=true">
					<div class="left">
						<div class="ico"><img src="../images/user/ico_3.png" /></div>
						<span>{{ $t('my.menu2') }}</span>
					</div>
					<div class="right">
						<div style="width:26px;height:26px;margin-right: 17px;"><img :src="require('../images/flags/'+lang+'.png')" style="border-radius: 13px;" /></div>
						<van-icon name="arrow" />
					</div>
				</div>
				<div class="menuitem" @click="active_huobi=true">
					<div class="left">
						<div class="ico"><img src="../images/user/ico_4.png" /></div>
						<span>{{ $t('my.menu3') }}</span>
					</div>
					<div class="right">
						<div style="margin-right: 17px;font-size: 17px;font-weight: 600	;">U.S. Dollar</div>
						<van-icon name="arrow" />
					</div>
				</div>
			</div>
			
			<div class="menu">
				<div class="menuitem" @click="$router.push({ path: '/' })">
					<div class="left">
						<div class="ico"><img src="../images/user/ico_5.png" /></div>
						<span>{{ $t('my.menu4') }}</span>
					</div>
					<div class="right">
						<van-icon name="arrow" />
					</div>
				</div>
				<div class="menuitem" @click="$router.push({ path: '/Orders' })">
					<div class="left">
						<div class="ico"><img src="../images/user/ico_6.png" /></div>
						<span>{{ $t('my.menu5') }}</span>
					</div>
					<div class="right">
						<van-icon name="arrow" />
					</div>
				</div>
				<div class="menuitem" @click="$router.push({ path: '/views' })">
					<div class="left">
						<div class="ico"><img src="../images/user/ico_7.png" /></div>
						<span>{{ $t('my.menu6') }}</span>
					</div>
					<div class="right">
						<van-icon name="arrow" />
					</div>
				</div>
				<div class="menuitem" @click="$router.push({ path: '/product' })">
					<div class="left">
						<div class="ico"><img src="../images/user/ico_8.png" /></div>
						<span>{{ $t('my.menu7') }}</span>
					</div>
					<div class="right">
						<van-icon name="arrow" />
					</div>
				</div>
				<div class="menuitem" @click="$router.push({ path: '/views' })">
					<div class="left">
						<div class="ico"><img src="../images/user/ico_9.png" /></div>
						<span>{{ $t('my.menu8') }}</span>
					</div>
					<div class="right">
						<van-icon name="arrow" />
					</div>
				</div>
			</div>
			
			<div class="menu">
				<div class="menuitem" @click="$router.push({ path: '/wallet' })">
					<div class="left">
						<div class="ico"><img src="../images/user/ico_10.png" /></div>
						<span>{{ $t('my.menu9') }}</span>
					</div>
					<div class="right">
						<van-icon name="arrow" />
					</div>
				</div>
				<div class="menuitem" @click="$router.push({ path: '/financ' })">
					<div class="left">
						<div class="ico"><img src="../images/user/ico_11.png" /></div>
						<span>{{ $t('my.menu10') }}</span>
					</div>
					<div class="right">
						<van-icon name="arrow" />
					</div>
				</div>
				<div class="menuitem" @click="$router.push({ path: '/share' })">
					<div class="left">
						<div class="ico"><img src="../images/user/ico_12.png" /></div>
						<span>{{ $t('my.menu11') }}</span>
					</div>
					<div class="right">
						<van-icon name="arrow" />
					</div>
				</div>
				<div class="menuitem">
					<div class="left">
						<div class="ico"><img src="../images/user/ico_13.png" /></div>
						<span>{{ $t('my.menu12') }}</span>
					</div>
					<div class="right">
						<van-icon name="arrow" />
					</div>
				</div>
			</div>
		</div>
		<van-popup v-model="active_lang">
			<div class="popupbox">
				<div class="popuptit">{{ $t('common.switch_lang') }}</div>
				<div class="sel_lang">
					<ul>
						<li @click="changeLang('en_us','en')">English</li>
						<li @click="changeLang('zh_cn','cn')">中文</li>
						<li @click="changeLang('zh_hk','hk')">繁体中文</li>
					</ul>
				</div>
				<div class="btnlist">
					<div @click="active_lang=false">{{ $t('common.qxbtn') }}</div>
					<div @click="active_lang=false">{{ $t('common.okbtn') }}</div>
				</div>
			</div>
		</van-popup>
		<van-popup v-model="active_huobi">
			<div class="popupbox">
				<div class="popuptit">{{ $t('common.switch_lang') }}</div>
				<div class="sel_lang">
					<ul>
						<li @click="active_huobi=false">U.S. Dollar</li>
					</ul>
				</div>
				<div class="btnlist">
					<div @click="active_huobi=false">{{ $t('common.qxbtn') }}</div>
					<div @click="active_huobi=false">{{ $t('common.okbtn') }}</div>
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
export default {
	data() {
		return {
			active_lang:false,
			active_huobi:false,
			seller:[],
			uid:0,
			lang:'zh_cn'
		};
	},
	mounted() {
		this.lang = localStorage.getItem("lang") || 'zh_cn';
	},
	created() {
		if (localStorage.getItem('access_token')) {
			this.uid = localStorage.getItem("sellerid");
			this.getUserInfo();
		} else {
			this.$router.push({path:'/Login'})
		}
	},
	methods: {
		changeLang(lang,flagname) {
			this.lang = lang;
			this.$i18n.locale = lang;
			localStorage.setItem("lang", lang);
			localStorage.setItem("langflag", flagname);
			this.active_lang=false;
		},
		getUserInfo() {
			this.$http({
				method: 'get',
				url: 'shopinfo',
				data:{
					id:this.uid
				}
			}).then(res => {
				if(res.code==1){
					this.seller = res.data;
				}
			})
		}
	}
}

</script>

<style lang='less' scoped>
.pageheader{
	position: fixed;
	width: 100%;
	z-index: 1999;
}
::v-deep .van-nav-bar {
	height: 48px;
	background: #fff;
	box-shadow: 0 1px 6px #ccc;
}

::v-deep .van-nav-bar__content {
	padding: 0 10px;
}

::v-deep .van-nav-bar__left {
	padding-left:10px;
	color: #000;
	font-size: 17px;
	font-weight: bold;
}

.homepage{
	padding: 73px 17px 60px;
	background: #f9f9f9;
}
img{
	width: 100%;
	height: 100%;
}
.avatarbox{
	background: #fff;
	display: flex;
	padding-bottom: 30px;
	.img{
		width: 66px;
		height: 66px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 14px;
	}
	.info{
		display: flex;
		flex-direction: column;
		.name{
			font-size: 17px;
			font-weight: 600;
			color: #000;
			line-height: 22px;
		}
		.account{
			font-size: 13px;
			color: #000;
			display: flex;
			align-items: center;
			line-height: 22px;
		}
		.rating{
			display: flex;
			align-items: center;
			display: flex;
			.fen{
				margin-right: 20px;
				display: flex;
			}
			.yirz{
				margin-right: 20px;
				font-size: 14px;
				color: #000;
				line-height: 22px;
			}
			.rz{
				background: url(../images/user/ico_1.png) no-repeat left top;
				background-size: 20px;
				padding-left: 25px;
			}
		}
	}
}
.menu{
	padding-top: 25px;
	border-top: solid 1px #ccc;
	.menuitem{
		display: flex;
		justify-content: space-between;
		padding-bottom: 33px;
		.left{
			display: flex;
			align-items: center;
			.ico{
				width: 24px;
				height: 24px;
				margin-right: 15px;
			}
		}
		.right{
			display: flex;
			align-items: center;
		}
	}
}

::v-deep .van-popup {
	width:80%;
}
.popupbox{
	padding: 22px;
	.popuptit{
		padding: 0 22px;
		margin-bottom: 22px;
		font-size: 18px;
		font-weight: 600;
		color: #000;
		line-height: 22px;
		text-align: center;
	}
	.btnlist{
		display: flex;
		justify-content: space-between;
		padding-top: 22px;
		div{
			display: flex;
			align-items: center;
			justify-content: center;
			width: 45%;
			height: 50px;
			border-radius: 6px;
			border: 1px solid #000;
			font-size: 15px;
			font-weight: 600;
			color: #000;
		}
		div:last-child{
			color: #fff;
			background: #000;
		}
	}
}
.sel_lang{
	li{
		margin-bottom: 16px;
	}
}
</style>
