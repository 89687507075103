<template>
	<div class="home-container">
		<div class="pageheader">
			<van-nav-bar class="nav-bar">
				<template #left>
					<van-icon name="arrow-left" color="#000000" @click="back()" />
					<span>{{ $t('my.menu10') }}</span>
				</template>
			</van-nav-bar>
		</div>
		<div class="homepage">
			<div class="selstatus">
				<div class="xiaok" @click="payactive=true">
					<div class="text">{{ $t('order.tab_all') }}</div>
					<van-icon name="arrow-down" color="#606266" />
				</div>
				<div class="xiaok" @click="wlactive=true">
					<div class="text">{{ $t('order.tab_all') }}</div>
					<van-icon name="arrow-down" color="#606266" />
				</div>
			</div>
			<div class="protabs">
				<div class="item">
					<div>{{ $t('my.shoure_totla') }}</div>
					<span>$1245.23</span>
				</div>
				<div class="item">
					<div>{{ $t('my.liren_totla') }}</div>
					<span>$135.21</span>
				</div>
			</div>
			<div class="prolist">
				<div class="item" v-for="(item,ind) in shop_pro" :key="ind">
					<div class="tit">
						{{ item.code }}
					</div>
					<div class="price">
						<div>{{ item.grand_total }}</div>
						<span>${{ item.grand_total }}</span>
					</div>
					<div class="time">{{ item.created_at }}</div>
				</div>
			</div>
		</div>
		<van-popup v-model="payactive">
			<div class="shoppic">
				<ul>
					<li>{{ $t('order.tab_all') }}</li>
					<li>{{ $t('order.paysta_paid') }}</li>
					<li>{{ $t('order.paysta_unpaid') }}</li>
				</ul>
			</div>
		</van-popup>
		<van-popup v-model="wlactive">
			<div class="shoppic">
				<ul>
					<li>{{ $t('order.tab_all') }}</li>
					<li>{{ $t('order.ddsta_pending') }}</li>
					<li>{{ $t('order.ddsta_confirmed') }}</li>
					<li>{{ $t('order.ddsta_on_the_way') }}</li>
					<li>{{ $t('order.ddsta_delivered') }}</li>
				</ul>
			</div>
		</van-popup>
	</div>
</template>

<script>
export default {
	data() {
		return {
			shop_pro:[],
			show_pigimg:'',
			payactive:false,
			wlactive:false,
			seller:[],
			uid:0,
			
		};
	},
	mounted() {
		
	},
	created() {
		if (localStorage.getItem('access_token')) {
			this.uid = localStorage.getItem("sellerid");
			this.getUserInfo();
			this.geshopProduct();
		} else {
			this.$router.push({path:'/Login'})
		}
	},
	methods: {
		back(){
			this.$router.push({path:'/mine'})
		},
		getUserInfo() {
			this.$http({
				method: 'get',
				url: 'shopinfo',
				data:{
					id:this.uid
				}
			}).then(res => {
				if(res.code==1){
					this.seller = res.data;
				}
			})
		},
		geshopProduct(){
			this.$http({
				method: 'get',
				url: 'shoporder',
			}).then(res => {
				if(res.code==1){
					this.shop_pro = res.data;
				}
			})
		}
	}
}

</script>

<style lang='less' scoped>
.pageheader{
	position: fixed;
	width: 100%;
	z-index: 1999;
}
::v-deep .van-nav-bar {
	height: 48px;
	background: #fff;
	box-shadow: 0 1px 6px #ccc;
}

::v-deep .van-nav-bar__content {
	padding: 0 10px;
}

::v-deep .van-nav-bar__left {
	padding-left:10px;
	color: #000;
	font-size: 17px;
	font-weight: bold;
}

.homepage{
	padding: 73px 17px 60px;
	background: #f9f9f9;
}
.protabs{
	display: flex;
    align-items: center;
    justify-content:space-around;
	margin-top: 17px;
	.item{
		overflow: hidden;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 97px;
		width: 45%;
		border: solid 1px #000;
		border-radius: 6px;
		span{
			font-size: 22px;
			font-weight: 600;
			line-height: 22px;
			text-align: center;
		}
		div{
			line-height: 40px;
		}
	}
	.item:first-child{
		color:#fff;
		background: #000;
	}
}
.searchbox{
	margin-top: 26px;
	::v-deep .van-field__left-icon {
		display: flex;
	}
}
.selstatus{
	display: flex;
	justify-content: space-between;
	.xiaok{
		background: #fff;
		width: 48%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 17px;
		height: 44px;
		.text{
			font-size: 14px;
		}
	}
}
.prolist{
	margin-top: 17px;
	.item{
		overflow: hidden;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		padding: 17px 22px;
		background: #fff;
		border-radius: 6px;
		margin-bottom: 17px;
		.tit{
			margin-bottom: 8px;
			font-size: 15px;
			font-weight: 600;
			color: #000;
			line-height: 22px;
		}
		.price{
			margin-bottom: 6px;
			font-size: 13px;
			color: #000;
			line-height: 22px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			span{
				font-size: 22px;
				text-align: right;
				font-weight: 600;
				color: #000;
				line-height: 22px;
			}
		}
		.time{
			font-size: 13px;
			color: #000;
			line-height: 22px;
			padding-left: 24px;
			background: url(../images/user/ico_17.png) no-repeat left center;
			background-size: 16px;
		}
	}
}
::v-deep .van-popup{
	border-radius: 16px;
    min-width: 80%;
}
.shoppic{
	li{
		text-align: center;
		padding: 11px 17px;
		font-size: 16px;
		font-weight: 600;
		border-bottom: 1px solid #ccc;
	}
}
</style>
