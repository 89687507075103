<template>
	<van-tabbar v-if="show" v-model="active" active-color="#7e5678" :border="true" inactive-color="#979799">
		<!-- 首页 -->
		<van-tabbar-item to="/Home">
			<template #icon="props">
				<img :src="props.active ? '/img/footer/homes.png' : '/img/footer/home.png'" :alt="$t('foorter.index')"
					v-show="show !== 0" />
				<img :src="props.active ? '/img/footer/homes.png' : '/img/footer/home.png'" :alt="$t('foorter.index')"
					:class="$t('foorter.index')" style="height: 4rem" v-show="show === 0" />
			</template>
		</van-tabbar-item>
		<!-- 商品 -->
		<van-tabbar-item to="/Product">
			<template #icon="props">
				<img :src="props.active ? '/img/footer/fulis.png' : '/img/footer/fuli.png'" :alt="$t('foorter.product')"
					v-show="show !== 1" />
				<img :src="props.active ? '/img/footer/fulis.png' : '/img/footer/fuli.png'" :alt="$t('foorter.product')"
					:class="$t('foorter.product')" style="height: 4rem" v-show="show === 1" />
			</template>
		</van-tabbar-item>
		<!-- 订单 -->
		<van-tabbar-item to="/orders">
			<template #icon="props">
				<img :src="props.active ? '/img/footer/lives.png' : '/img/footer/live.png'" :alt="$t('foorter.orders')"
					v-show="show !== 2" />
				<img :src="props.active ? '/img/footer/lives.png' : '/img/footer/live.png'" :alt="$t('foorter.orders')"
					:class="$t('foorter.orders')" style="height: 4rem" v-show="show === 2" />
			</template>
		</van-tabbar-item>

		<!-- 我的 -->
		<van-tabbar-item to="/Mine">
			<template #icon="props">
				<img :src="props.active ? '/img/footer/users.png' : '/img/footer/user.png'" :alt="$t('foorter.my')"
					v-show="show !== 3" />
				<img :src="props.active ? '/img/footer/users.png' : '/img/footer/user.png'" :alt="$t('foorter.my')"
					:class="$t('foorter.my')" style="height: 4rem" v-show="show === 3" />
			</template>
		</van-tabbar-item>
	</van-tabbar>
</template>

<script>
export default {
	data() {
		return {
			show: false,
			active: 0
		};
	},
	methods: {},
	watch: {
		$route(to) {
			if (to.name == "home") {
				this.active = 0;
				this.show = true;
			} else if (to.name == "product") {
				this.active = 1;
				this.show = true;
			} else if (to.name == "orders") {
				this.active = 2;
				this.show = true;
			} else if (to.name == "mine") {
				this.active = 3;
				this.show = true;
			} else {
				this.show = false;
			}
		},
	},
	created() {
		if (this.$route.name == "home") {
			this.active = 0;
			this.show = true;
		} else if (this.$route.name == "product") {
			this.active = 1;
			this.show = true;
		} else if (this.$route.name == "orders") {
			this.active = 2;
			this.show = true;
		} else if (this.$route.name == "mine") {
			this.active = 3;
			this.show = true;
		} else {
			this.show = false;
		}
	},
};
</script>

<style lang="less" scoped>
@tabbar-height: 55px;
@tabbar-img: 27px;

.van-tabbar {
	height: @tabbar-height;
	z-index: 9999;
	background: #1f1c1f;
}
::v-deep .van-tabbar-item .van-tabbar-item__icon{
	width:19px;
	height: 19px;
}
::v-deep .van-tabbar-item .van-tabbar-item__icon img {
	height: 100%;
}

::v-deep .van-tabbar-item--active{
	background: none;
}

::v-deep .van-tabbar-item--active .van-tabbar-item__icon {
	background: none;
	width: 39px;
	height: 39px;
}

.van-tabbar-item {
	font-size: 14px;
}

.tui {
	width: 4rem;
	height: 4rem !important;
	margin-top: -7.333vw;
	background-color: white;
	border-radius: 50%;
	border: 10px solid white;
	z-index: 10;
}

[class*="van-hairline"]::after {
	border: none !important;
}
</style>
