<template>
	<div class="home-container">
		<div class="pageheader">
			<van-nav-bar class="nav-bar">
				<template #left>
					<van-icon name="arrow-left" color="#fff" @click="back()" />
					<span>{{ $t('my.menu9') }}</span>
				</template>
			</van-nav-bar>
		</div>
		<div class="pagebg"></div>
		<div class="homepage">
			<div class="moneybox">
				<span>{{ $t('my.balance') }}</span>
				<span>${{ seller.money }}</span>
				<span>{{ $t('my.balance_ddz') }}</span>
				<span>${{ seller.money }}</span>
			</div>
			<div class="tabsbox">
				<div :class="contype==0?'sel':''" @click="showcon(0)">{{ $t('my.recharge_record') }}</div>
				<div :class="contype==1?'sel':''" @click="showcon(1)">{{ $t('my.withdraw_record') }}</div>
			</div>
			<div class="recordlist">
				<div class="item" v-for="(witem,wind) in withdraw" :key="wind" v-show="contype==1">
					<div>
						<span v-if="witem.w_type==2">{{ $t('withdraw.online_re') }}</span>
						<span v-else>{{ $t('withdraw.offline_re') }}</span>
						<span class="jiang">-${{ witem.amount }}</span>
					</div>
					<div>
						<span>{{ witem.time }}</span>
						<span v-if="witem.status==1" class="pz">{{ $t('recharge.repass') }}</span>
						<span v-else class="pz">{{ $t('recharge.reunpass') }}</span>
					</div>
					<div>{{ $t('withdraw.info') }}{{ witem.message }}</div>
					<div>{{ $t('withdraw.remark') }}{{ witem.remarks }}</div>
				</div>
				<div class="item" v-for="(item,ind) in recharge" :key="ind" v-show="contype==0">
					<div>
						<span v-if="item.offline_payment==1">{{ $t('recharge.offline_re') }}</span>
						<span v-else>{{ $t('recharge.online_re') }}</span>
						<span v-if="item.type==1" class="jia">+${{ item.amount }}</span>
						<span v-else class="jiang">-${{ item.amount }}</span>
					</div>
					<div>
						<span>{{ item.time }}</span>
						<span v-if="item.approval==1" class="pz">{{ $t('recharge.repass') }}</span>
						<span v-else class="pz">{{ $t('recharge.reunpass') }}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="bottombtn">
			<div @click="active_r=true">{{ $t('my.recharge') }}</div>
			<div @click="active_w=true">{{ $t('my.withdraw') }}</div>
		</div>	
		
		<van-popup v-model="active_w">
			<div class="popupbox">
				<div class="popuptit">{{ $t('my.withdraw') }}</div>
				<div class="conbox">
					<div class="item">
						<div class="tit">{{ $t('my.money') }}</div>
						<van-field v-model="money" class="input"></van-field>
					</div>
					<div class="item">
						<div class="tit">{{ $t('withdraw.sktype') }}</div>
						<van-radio-group v-model="w_type" @change="changeFn">
							<van-radio name="3">USDT</van-radio>
							<van-radio name="2">银行卡</van-radio>
						</van-radio-group>
					</div>
					<!-- <div class="item">
						<div class="tit">{{ $t('setting.band_name') }}</div>
						<van-field v-model="paysetdata.bank_name" class="input"></van-field>
					</div>
					<div class="item">
						<div class="tit">{{ $t('setting.username') }}</div>
						<van-field v-model="paysetdata.username" class="input"></van-field>
					</div>
					<div class="item">
						<div class="tit">{{ $t('setting.band_account') }}</div>
						<van-field v-model="paysetdata.bank_account" class="input"></van-field>
					</div> -->
					<div class="item">
						<div class="tit">{{ $t('setting.band_beizhu') }}</div>
						<van-field v-model="beizhu" class="input"></van-field>
					</div>
				</div>
				<div class="btnlist">
					<div @click="active_w=false">{{ $t('common.qxbtn') }}</div>
					<div @click="goWithdraw()">{{ $t('common.okbtn') }}</div>
				</div>
			</div>
		</van-popup>
		
		
		<van-popup v-model="active_r">
			<div class="popupbox">
				<div class="popuptit">{{ $t('my.recharge') }}</div>
				<div class="conbox">
					<van-radio-group v-model="bankvalue" @change="changeBank">
						<div class="witem">
							<div class="left">
								<div class="img"><img src="../images/user/bank1.png" /></div>
								<span>Paypal</span>
							</div>
							<van-radio name="Paypal"></van-radio>
						</div>
						<div class="witem">
							<div class="left">
								<div class="img"><img src="../images/user/bank2.png" /></div>
								<span>Googlepay</span>
							</div>
							<van-radio name="Googlepay"></van-radio>
						</div>
						<div class="witem">
							<div class="left">
								<div class="img"><img src="../images/user/bank3.png" /></div>
								<span>Ccpay</span>
							</div>
							<van-radio name="Ccpay"></van-radio>
						</div>
						<div class="witem">
							<div class="left">
								<div class="img"><img src="../images/user/bank4.png" /></div>
								<span>Paynow</span>
							</div>
							<van-radio name="Paynow"></van-radio>
						</div>
					</van-radio-group>
				</div>
				<div class="btnlist">
					<div @click="active_r=false">{{ $t('common.qxbtn') }}</div>
					<div @click="goRecharge()">{{ $t('common.okbtn') }}</div>
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
export default {
	data() {
		return {
			active_w:false,
			active_r:false,
			seller:[],
			uid:0,
			w_type:'2',
			bankvalue:'',
			money:0,
			beizhu:'',
			recharge:[],
			withdraw:[],
			contype:0
		};
	},
	mounted() {
		
	},
	created() {
		if (localStorage.getItem('access_token')) {
			this.uid = localStorage.getItem("sellerid");
			this.getUserInfo();
			this.getRecharge();
		} else {
			this.$router.push({path:'/Login'})
		}
	},
	methods: {
		showcon(num){
			this.contype=num;
			if(num==1){
				this.getWithdraw();
			}
			else{
				this.getRecharge();
			}
		},
		goWithdraw(){
			if(this.money==''){
				this.$toast(this.$t("recharge.input_tip"));
				return false;
			}
			this.$http({
				method: 'post',
				url: 'do_withdraw',
				data:{
					money:this.money,
					beizhu:this.beizhu,
					w_type:this.w_type,
				}
			}).then(res => {
				if(res.code==1){
					this.$toast(this.$t("setting.uploadSuccess"));
					this.active_w=false;
				}
			})

		},
		goRecharge(){
			if(this.bankvalue==''){
				this.$toast(this.$t("recharge.bank_tip"));
				return false;
			}
			this.$router.push({ path: '/Recharge?bank='+ this.bankvalue})
		},
		changeFn(){
			console.log(this.checked)
		},
		changeBank(){
			console.log(this.bankvalue)
		},
		back(){
			this.$router.push({path:'/mine'})
		},
		getUserInfo() {
			this.$http({
				method: 'get',
				url: 'shopinfo',
				data:{
					id:this.uid
				}
			}).then(res => {
				if(res.code==1){
					this.seller = res.data;
				}
			})
		},
		getRecharge(){
			this.$http({
				method: 'get',
				url: 'getrecharge'
			}).then(res => {
				if(res.code==1){
					this.recharge = res.data;
				}
			})
		},
		getWithdraw(){
			this.$http({
				method: 'get',
				url: 'getwithdraw'
			}).then(res => {
				if(res.code==1){
					this.withdraw = res.data;
				}
			})
		}
	}
}

</script>

<style lang='less' scoped>
.home-container{
	background: #f9f9f9;
	min-height: 100vh;
}
.pageheader{
	position: fixed;
	width: 100%;
	z-index: 9999;
}
::v-deep .van-nav-bar {
	height: 48px;
	background: #000;
}

::v-deep .van-nav-bar__content {
	padding: 0 10px;
}

::v-deep .van-nav-bar__left {
	padding-left:10px;
	color: #fff;
	font-size: 17px;
	font-weight: bold;
}
.pagebg{
    position: absolute;
    z-index: 1;
    top: calc(-48px + 0px);
    width: 100%;
    height: 321px;
    background-image: url(../images/user/bg_wallet.png);
    background-size: cover;
}
.homepage{
	padding: 50px 17px 70px;
	background: none;
}
.moneybox{
	position: relative;
    width: 100%;
    min-height: 251px;
    margin: 0 auto;
    box-sizing: border-box;
    border-radius: 27px;
    background-image: url(../images/user/bg_wallet_money.png);
	background-position: -18px 0;
    background-size: 110% 253px;
    background-repeat: no-repeat;
    padding: 49px 0px 30px 30px;
	z-index: 9;
	color: #fff;
	display: flex;
	flex-direction: column;
	span:first-child{
		font-size: 17px;
		line-height: 22px;
		margin-bottom: 5px;
		font-weight: 600;
	}
	span:nth-child(2){
		font-size: 35px;
		font-weight: 600;
		color: #f1f5f9;
		margin-bottom: 17px;
	}
	span:nth-child(3){
		font-size: 13px;
		line-height: 22px;
		color: #f1f5f9;
		margin-bottom: 4px;
	}
	span:last-child{
		font-size: 22px;
		font-weight: 600;
		color: #f1f5f9;
	}
}
.tabsbox{
	width: 100%;
	display: flex;
	justify-content: space-around;
	.sel{
		font-weight: 600;
		border-bottom: solid 1px #000;
	}
}
.recordlist{
	margin-top: 17px;
	.item{
		min-height: 88px;
		padding: 16px 22px;
		margin-bottom: 13px;
		background-color: #fff;
		box-sizing: border-box;
		border-radius: 6px;
		div{
			display: flex;
			justify-content: space-between;
		}
		div:first-child{
			span:first-child{
				font-size: 17px;
				line-height: 22px;
				font-weight: 600;
				color: #000;
				margin-bottom: 2px;
			}
			span:last-child{
				font-size: 22px;
				font-weight: 600;
			}
			span.jia{
				color: rgb(31, 201, 28);
			}
			span.jiang{
				color: rgb(201, 28, 28);
			}
		}
		div:last-child{
			span:first-child{
				font-size: 13px;
				margin-top: 2px;
				color: #989898;
			}
			span:last-child{
				font-size: 13px;
			}
			span.pz{
				color: #713fff;
			}
		}
	}
}
.bottombtn{
	position: fixed;
    z-index: 3;
    left: 0;
    right: 0;
    bottom: 16px;
    display: flex;
    justify-content: space-around;
    width: 100%;
	div{
		width: 33%;
		height: 50px;
		background: #a8d3ff;
		border-radius: 6px;
		line-height: 50px;
		text-align: center;
	}
	div:last-child{
		background: #000;
		color: #fff;
	}
}

::v-deep .van-popup {
	width:80%;
	max-height: 80%;
}
.popupbox{
	padding: 22px;
	.popuptit{
		padding: 0 22px;
		margin-bottom: 22px;
		font-size: 18px;
		font-weight: 600;
		color: #000;
		line-height: 22px;
		text-align: center;
	}
	.btnlist{
		display: flex;
		justify-content: space-between;
		padding-top: 22px;
		div{
			display: flex;
			align-items: center;
			justify-content: center;
			width: 45%;
			height: 50px;
			border-radius: 6px;
			border: 1px solid #000;
			font-size: 15px;
			font-weight: 600;
			color: #000;
		}
		div:last-child{
			color: #fff;
			background: #000;
		}
	}
}

.input{
	border: solid 1px #dadbde;
	border-radius: 4px;
	height: 38px;
	padding: 6px 9px;
}

img{
	width: 100%;
	height: 100%;
}
.conbox{
	.item{
		margin-bottom: 10px;
		.tit{
			font-size: 14px;
		}
	}
	.witem{
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 10px;
		.left{
			display: flex;
			align-items: center;
			.img{
				width: 30px;
				height: 30px;
				margin-right: 10px;
				img{
					border-radius: 15px;
				}
			}
		}
		.tit{
			font-size: 14px;
		}
	}
}
</style>
