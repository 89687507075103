<template>
	<div class="home-container">
		<div class="pageheader">
			<van-nav-bar class="nav-bar">
				<template #left>
					<span>{{ $t('foorter.product') }}</span>
				</template>
			</van-nav-bar>
		</div>
		<div class="homepage">
			<div class="protabs">
				<div class="sel">{{ $t('foorter.product') }}</div>
				<div @click="$router.push({ path: '/reviews' })">{{ $t('product.reviews') }}</div>
			</div>
			<div class="prolist">
				<div class="ptit">{{ $t('product.allpro') }}</div>
				<div class="item" v-for="(item,ind) in shop_pro" :key="ind">
					<div class="img" @click="showbigpic(item.image)"><img :src="item.image" alt=""></div>
					<div class="con">
						<div class="tit">{{ item.name }}</div>
						<div class="price">${{ item.unit_price }}</div>
					</div>
				</div>
			</div>
		</div>
		<van-popup v-model="active" get-container=".homepage">
			<div class="shoppic">
				<div class="img"><img :src="show_pigimg" /></div>
				<div style="height: 50px;font-size:16px;width:100%;text-align: center; line-height: 50px; color: #fff;background: #000;" @click="active=false">{{ $t('common.okbtn') }}</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
export default {
	data() {
		return {
			shop_pro:[],
			show_pigimg:'',
			active:false,
			seller:[],
			uid:0,
			
		};
	},
	mounted() {
		
	},
	created() {
		if (localStorage.getItem('access_token')) {
			this.uid = localStorage.getItem("sellerid");
			this.getUserInfo();
			this.geshopProduct();
		} else {
			this.$router.push({path:'/Login'})
		}
	},
	methods: {
		showbigpic(img){
			this.active= true;
			this.show_pigimg = img;
		},
		getUserInfo() {
			this.$http({
				method: 'get',
				url: 'shopinfo',
				data:{
					id:this.uid
				}
			}).then(res => {
				if(res.code==1){
					this.seller = res.data;
				}
			})
		},
		geshopProduct(){
			this.$http({
				method: 'get',
				url: 'shop_product',
			}).then(res => {
				if(res.code==1){
					this.shop_pro = res.data;
				}
			})
		}
	}
}

</script>

<style lang='less' scoped>
.pageheader{
	position: fixed;
	width: 100%;
	z-index: 9999;
}
::v-deep .van-nav-bar {
	height: 48px;
	background: #fff;
	box-shadow: 0 1px 6px #ccc;
}

::v-deep .van-nav-bar__content {
	padding: 0 10px;
}

::v-deep .van-nav-bar__left {
	padding-left:10px;
	color: #000;
	font-size: 17px;
	font-weight: bold;
}
.homepage{
	padding: 73px 17px 60px;
	background: #f9f9f9;
}
.protabs{
	display: flex;
    align-items: center;
    justify-content: space-between;
	div{
		overflow: hidden;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 181px;
		height: 77px;
		padding-left: 78px;
		padding-right: 14px;
		border-radius: 8px;
		text-align: right;
		font-size: 14px;
	}
	div:first-child{
		background: #000 url(../images/product/tab_1.png) 0 0 no-repeat;
		background-size: 77px;
		color: #fff;
	}
	div:last-child{
		background: url(../images/product/tab_2.png) #a8d3ff;
		background-repeat: no-repeat;
		background-position: 0 0;
		background-size: 77px;
	}
}
.prolist{
	margin-top: 17px;
	.ptit{
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 22px;
		font-weight: 700;
		color: #000;
		line-height: 33px;
	}
	.item{
		width: 100%;
		height: 128px;
		margin: 17px auto 0;
		background: #fff;
		border-radius: 6px;
		overflow: hidden;
		display: flex;
		.img{
			width: 128px;
			height: 128px;
			img{
				width: 100%;
				height: 100%;
			}
		}
		.con{
			flex: 1;
			padding: 13px 15px 15px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.price{
				font-size: 22px;
				font-weight: 700;
				color: #000;
				line-height: 22px;
			}
		}
	}
}
.shoppic{
	width: 300px;
	height: 250px;
	display:flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	background: #fff;
	.img{
		width: 100%;
		height: 200px;
		img{
			width:100%;
			height: 100%;
		}
	}

}
</style>
